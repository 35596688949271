import React, { useState } from 'react';
import axios from 'axios';

export const MayoristasAdd = () => {
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [web, setWeb] = useState("");
  const [emailGeneral, setEmailGeneral] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post("https://api.hubapp.cl/api/mayoristas", {
        nombre: nombre,
        telefono: telefono,
        direccion: direccion,
        web: web,
        email_general: emailGeneral,
      })
      .then((response) => {
        setSuccess(true);
        setError("");
      })
      .catch((error) => {
        setError("Error al agregar el mayorista.");
        setSuccess(false);
      });
  }

  return (
    <div className="container mx-auto px-4">
      <form onSubmit={handleSubmit} className="space-y-4 " >
        <input
          type="text"
          placeholder="Nombre"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          className="block w-full p-2 border border-gray-200 rounded dark:text-gray-200 dark:bg-secondary-dark-bg"
        />
        <input
          type="text"
          placeholder="Teléfono"
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
          className="block w-full p-2 border border-gray-200 rounded"
        />
        <input
          type="text"
          placeholder="Dirección"
          value={direccion}
          onChange={(e) => setDireccion(e.target.value)}
          className="block w-full p-2 border border-gray-200 rounded"
        />
        <input
          type="text"
          placeholder="Web"
          value={web}
          onChange={(e) => setWeb(e.target.value)}
          className="block w-full p-2 border border-gray-200 rounded"
        />
        <input
          type="text"
          placeholder="Email General"
          value={emailGeneral}
          onChange={(e) => setEmailGeneral(e.target.value)}
          className="block w-full p-2 border border-gray-200 rounded"
        />

        {success && (
          <div className="text-green-500">Mayorista agregado exitosamente.</div>
        )}
        {error && (
          <div className="text-red-500">{error}</div>
        )}
        <button
          type="submit"
          className="w-full py-2 px-4 bg-green-500 text-white rounded hover:bg-green-700 duration-500"
        >
          Agregar Mayorista
        </button>
      </form>
    </div>
  );
};
