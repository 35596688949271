import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export const IntcomexProducts = () => {
  /* const [, setLoading] = useState(false); */
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  /* const [searchTerm, setSearchTerm] = useState(""); */
  const [subCategoriaFilter, setSubCategoriaFilter] = useState("");
  const [marcaFilter, setMarcaFilter] = useState("");
  const [partNumberFilter, setPartNumberFilter] = useState("");
  const [skuFilter, setSkuFilter] = useState("");
  const [sortOrder, setSortOrder] = useState({
    column: "stock",
    order: "asc",
    stockOrder: "asc",
  });
  const [stockOrder, setStockOrder] = useState("asc");
  const [/*prices*/, setPrices] = useState([]);
  const [/*inventory*/, setInventory] = useState([]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    if (name === "subCategoria") {
      setSubCategoriaFilter(value);
    } else if (name === "marca") {
      setMarcaFilter(value);
    } else if (name === "partNumber"){
      setPartNumberFilter(value);
    } else if (name === "sku"){
      setSkuFilter(value);
    }
  };

  const handleStockSorting = () => {
    setStockOrder(stockOrder === "asc" ? "desc" : "asc");
    setSortOrder({
      column: "stock",
      order: stockOrder === "asc" ? "desc" : "asc",
    });
  };

  const handlePriceSorting = () => {
    setSortOrder({
      column: "price",
      order:
        sortOrder.column === "price" && sortOrder.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  useEffect(() => {
    axios
      .get(
        "https://api.hubapp.cl/api/mayorista/intcomex/getfiltercatalog"
      )
      .then((response) => setProducts(response.data))
      .catch((error) => console.error(error));

    axios
      .get(
        "https://api.hubapp.cl/api/mayorista/intcomex/getpricelist"
      )
      .then((response) => setPrices(response.data))
      .catch((error) => console.error(error));

    axios
      .get(
        "https://api.hubapp.cl/api/mayorista/intcomex/getinventory"
      )
      .then((response) => setInventory(response.data))
      .catch((error) => console.error(error));
  }, []);

  const navigate = useNavigate();

  const handleDesplegar = (product) => {
    navigate("/agregando-producto-intcomex", { state: { product: product } });
  };

  /* const searchedProducts = async (term) => {
    setLoading(true);
    try {
      let response;
      if (term === "") {
        response = await axios.get(
          "https://api.hubapp.cl/api/mayorista/intcomex/getfiltercatalog"
        );
      } else {
        response = await axios.get(
          "https://api.hubapp.cl/api/mayorista/intcomex/getfiltercatalog"
        );
      }

      const filteredData = response.data.filter(
        (product) =>
          product.Mpn.toLowerCase().includes(term.toLowerCase()) ||
          product.Description.toLowerCase().includes(term.toLowerCase()) ||
          product.Brand.Description.toLowerCase().includes(term.toLowerCase())
      );
      setProducts(filteredData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
 */
  /*const getProductPrice = (sku) => {
    const productPrice = prices.find((price) => price.Sku === sku);
    if (productPrice) {
      return productPrice.Price.UnitPrice;
    }
    return null;
  };
*/


  const sortedProducts = [...products].sort((a, b) => {
    if (sortOrder.column === "stock") {
      return stockOrder === "asc" ? a.Stock - b.Stock : b.Stock - a.Stock;
    } else if (sortOrder.column === "price") {
      return sortOrder.order === "asc"
        ? a.Price - b.Price
        : b.Price - a.Price;
    }
    // default case: return zero
    return 0;
  });


  return (
    <div className="container mx-auto px-4">
      <div className="flex items-center justify-center mt-5 mb-5">
      <input
          type="text"
          placeholder="Filtro por sub categoría"
          name="subCategoria"
          value={subCategoriaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
         <input
          type="text"
          name="marca"
          placeholder="Filtrar por Marca"
          value={marcaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
        <input
          type="text"
          name="partNumber"
          placeholder="Filtrar por PartNumber"
          value={partNumberFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
        <input
          type="text"
          name="sku"
          placeholder="Filtrar por SKU"
          value={skuFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr
              className="rounded-lg text-sm font-medium text-gray-700 text-left"
              style={{ fontSize: "0.9674rem" }}
            >
              <th className="px-4 py-2 bg-gray-200">Acciones</th>
              <th className="px-4 py-2 bg-gray-200 cursor-pointer" onClick={handlePriceSorting}>Precio</th>
              <th
  className="px-4 py-2 bg-gray-200 cursor-pointer"
  onClick={handleStockSorting}
>
  <div className="flex items-center">
    Stock{" "}
    <FontAwesomeIcon
      icon={faSortDown}
      className={`ml-1 ${
        sortOrder.column === "stock" ? "text-green-600" : "text-gray-400"
      }`}
    />
  </div>
</th>
              <th
                className="px-4 py-2 bg-gray-200"
              >
                Sku Mayorista
              </th>
              <th
                className="px-4 py-2 bg-gray-200 cursor-pointer"
              >
                Part Number
              </th>
              <th className="px-4 py-2 bg-gray-200">Categoría</th>
              <th className="px-4 py-2 bg-gray-200">Descripción</th>
              <th className="px-4 py-2 bg-gray-200">Marca</th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts
            .filter((product) => {
              return product.Category.Description.toLowerCase().includes(subCategoriaFilter.toLowerCase()) && 
              product.Brand.Description.toLowerCase().includes(marcaFilter.toLowerCase()) && 
              product.Mpn.toLowerCase().includes(partNumberFilter.toLowerCase()) &&
              product.Sku.toLowerCase().includes(skuFilter.toLowerCase())
             })
              .slice(
                currentPage * itemsPerPage,
                (currentPage + 1) * itemsPerPage
              )
              .map((product) => (
                <tr
                  key={product.codigoTg}
                  className="text-sm font-normal text-gray-700"
                >
                  <td>
                    <button
                      className="px-4 py-4 border-2 border-green-300 text-sm text-white bg-green-600 hover:bg-green-700"
                      onClick={() => handleDesplegar(product)}
                    >
                      Desplegar
                    </button>
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Price}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Stock}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Sku}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Mpn}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Category.Description}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Description}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.Brand.Description}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        pageCount={Math.ceil(products.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination flex justify-center space-x-2"}
        pageClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-500 hover:text-white duration-500"
        }
        previousClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        nextClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        breakClassName={"break px-3 py-2 border rounded text-sm cursor-pointer"}
        activeClassName={"active bg-green-500 text-white"}
      />
    </div>
  );
};
