import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

export const SellersShowOrder = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(7);
  const [searchTerm, setSearchTerm] = useState('');
  const [customerName] = useState('');
  const [paymentMethod] = useState('');
  const [status] = useState('');

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`https://api.hubapp.cl/api/seller/falabella/getorders?CustomerName=${customerName}&PaymentMethod=${paymentMethod}&Status=${status}`);
        setData(result.data.Orders);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [customerName, paymentMethod, status]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

  const filteredData = searchTerm
    ? data.filter((order) => {
      return (
        ((order.Order.CustomerFirstName?.toLowerCase() || '') + ' ' + (order.CustomerLastName?.toLowerCase() || '')).includes(searchTerm.toLowerCase()) &&
        (paymentMethod === '' || order.Order.PaymentMethod.toLowerCase().includes(paymentMethod.toLowerCase())) &&
        (status === '' || (order.Order.Statuses && order.Order.Statuses[0] && order.Order.Statuses[0].Status.toLowerCase().includes(status.toLowerCase())))
      );
    })
    : data;

  return (
    <div className='container mx-auto px-4'>
      <div className="flex items-center justify-center mt-5 mb-5">
        <input
          type="text"
          placeholder="Filtro por Nombre Cliente, Metodo de pago, Estado"
          value={searchTerm}
          onChange={handleSearch}
          className='border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none'
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr className="rounded-lg text-sm font-medium text-gray-700 text-left" style={{fontSize: '0.9674rem'}}>
              <th className="px-4 py-2 bg-gray-200 ">Order Id</th>
              <th className="px-4 py-2 bg-gray-200 ">Customer Name</th>
              <th className="px-4 py-2 bg-gray-200 ">Order Number</th>
              <th className="px-4 py-2 bg-gray-200 ">Payment Method</th>
              <th className="px-4 py-2 bg-gray-200 ">Price</th>
              <th className="px-4 py-2 bg-gray-200 ">Status</th>
              <th className="px-4 py-2 bg-gray-200 ">Dirección</th>
              <th className="px-4 py-2 bg-gray-200 ">Número</th>
              <th className="px-4 py-2 bg-gray-200 ">DEPTO/CASA</th>
              <th className="px-4 py-2 bg-gray-200 ">City</th>
              <th className="px-4 py-2 bg-gray-200 ">Region</th>
              <th className="px-4 py-2 bg-gray-200 ">Ward</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
              .map((order) => (
                <tr key={order.OrderId} className="text-sm font-normal text-gray-700">
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.OrderId}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {order.Order.CustomerFirstName} {order.CustomerLastName}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.OrderNumber}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.PaymentMethod}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.Price}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {order.Order.Statuses && order.Order.Statuses[0] && order.Order.Statuses[0].Status}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.Address1}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.Address2}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.Address3}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.City}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.Region}</td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">{order.Order.AddressBilling && order.Order.AddressBilling.Ward}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
  previousLabel={'Anterior'}
  nextLabel={'Siguiente'}
  breakLabel={'...'}
  pageCount={Math.ceil(filteredData.length / itemsPerPage)}
  onPageChange={handlePageChange}
  containerClassName={'pagination flex justify-center space-x-2'}
  pageClassName={'page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-500 hover:text-white duration-500'}
  previousClassName={'page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500'}
  nextClassName={'page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500'}
  breakClassName={'break px-3 py-2 border rounded text-sm cursor-pointer'}
  activeClassName={'active bg-green-500 text-white'}
/>
    </div>
  );
};
