import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export const TecnogProducts = () => {
  //const [, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  //const [searchTerm, setSearchTerm] = useState("");
  const [subCategoriaFilter, setSubCategoriaFilter] = useState("");
  const [marcaFilter, setMarcaFilter] = useState("");
  const [partNumberFilter, setPartNumberFilter] = useState("");
  const [sortOrder, setSortOrder] = useState({
    column: "stock",
    order: "asc",
  });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    if (name === "subCategoria") {
      setSubCategoriaFilter(value);
    } else if (name === "marca") {
      setMarcaFilter(value);
    } else if (name === "partNumber"){
      setPartNumberFilter(value);
    }
  };

  const handleStockSorting = () => {
    setSortOrder({
      column: "stock",
      order:
        sortOrder.column === "stock" && sortOrder.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  const handlePriceSorting = () => {
    setSortOrder({
      column: "precio",
      order:
        sortOrder.column === "precio" && sortOrder.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  useEffect(() => {
    axios
      .get(
        `https://api.hubapp.cl/api/mayorista/tecnoglobal/filterproducts`
      )
      .then((response) => setProducts(response.data))
      .catch((error) => console.error(error));
  }, []);

  const navigate = useNavigate();

  const handleDesplegar = (product) => {
    navigate("/agregando-producto", { state: { product: product } });
  };

  /*const searchedProducts = () => {
    setLoading(true);
    try {
      let filteredData = products;
      if (subCategoriaFilter !== "") {
        filteredData = products.filter((product) =>
          product.subCategoria.toLowerCase().includes(subCategoriaFilter.toLowerCase())
        );
      }
      setProducts(filteredData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };*/
  
  
  const sortedProducts = [...products].sort((a, b) => {
    if (sortOrder.column === "stock") {
      return sortOrder.order === "asc"
        ? a.stockDisp - b.stockDisp
        : b.stockDisp - a.stockDisp;
    } else if (sortOrder.column === "precio") {
      return sortOrder.order === "asc"
        ? a.precio - b.precio
        : b.precio - a.precio;
    }
    // default case: return zero
    return 0;
  });

  return (
    <div className="container mx-auto px-4">
      <div className="flex items-center justify-center mt-5 mb-5">
        <input
          type="text"
          name="subCategoria"
          placeholder="Filtrar por SubCategoría"
          value={subCategoriaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
          <input
          type="text"
          name="marca"
          placeholder="Filtrar por Marca"
          value={marcaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
                  <input
          type="text"
          name="partNumber"
          placeholder="Part Number | SKU"
          value={partNumberFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
        {/*
        <button
          className={`ml-4 px-4 py-2 rounded text-white ${
            loading ? "bg-gray-500" : "bg-green-600 hover:bg-green-700"
          } duration-500`}
          disabled={loading}
          onClick={handleUpdateStockTecnoglobal}
        >
          Actualizar stock de productos
        </button>
        */}
      </div>
      
        
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr className="rounded-lg text-sm font-medium text-gray-700 text-left" style={{fontSize: '0.9674rem'}}>
              <th className="px-4 py-2 bg-gray-200">
                Acciones
              </th>
              <th
                className="px-4 py-2 bg-gray-200 cursor-pointer"
                onClick={handlePriceSorting}
              >
                Precio
              </th>
              <th
  className="px-4 py-2 bg-gray-200 cursor-pointer hover:bg-green-300 flex items-center"
  onClick={handleStockSorting}
>
  <span className="mr-2 px-2 py-3">Stock</span>
  <FontAwesomeIcon icon={faSortDown} className="w-4 fill-current text-green-600" />
</th>
              <th className="px-4 py-2 bg-gray-200">
                Part Number
              </th>
              <th className="px-4 py-2 bg-gray-200">
                Categoría
              </th>
              <th className="px-4 py-2 bg-gray-200">
                Descripción
              </th>

              <th className="px-4 py-2 bg-gray-200">
                Marca
              </th>
              <th className="px-4 py-2 bg-gray-200">
                Oferta (0 No)
              </th>
              <th className="px-4 py-2 bg-gray-200">
                Sub Categoría
              </th>
              <th className="px-4 py-2 bg-gray-200">
                Sku Tecno Global
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedProducts
              .filter((product) => {
               return product.subCategoria.toLowerCase().includes(subCategoriaFilter.toLowerCase()) && 
               product.marca.toLowerCase().includes(marcaFilter.toLowerCase()) && 
               product.pnFabricante.toLowerCase().includes(partNumberFilter.toLowerCase())
              })
              .slice(
                currentPage * itemsPerPage,
                (currentPage + 1) * itemsPerPage
              )
              .map((product) => (
                <tr key={product.codigoTg} className="text-sm font-normal text-gray-700">
                  <button
                    className="px-4 py-4 border-2 border-green-300 text-sm text-white bg-green-600 hover:bg-green-700"
                    onClick={() => handleDesplegar(product)}
                  >
                    Desplegar
                  </button>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.precio}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.stockDisp}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.pnFabricante}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.categoria}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.descripcion}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.marca}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.ofertaSiNo}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.subCategoria}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.codigoTg}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        pageCount={Math.ceil(products.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination flex justify-center space-x-2"}
        pageClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-500 hover:text-white duration-500"
        }
        previousClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        nextClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        breakClassName={"break px-3 py-2 border rounded text-sm cursor-pointer"}
        activeClassName={"active bg-green-500 text-white"}
      />
    </div>
  );
};
