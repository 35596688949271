import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import axios from "axios";

export const AgregandoProductoIngram = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product = location.state.product;
  const [descripcion, /*setDescripcion*/] = useState("");
  const [descripcionCorta, /*setDescripcionCorta*/] = useState("");

  /*
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.hubapp.cl/api/icecat/comparar/${product.upcCode}`
        );
        const data = response.data;

        setDescripcion(data.descripcion || "");
        setDescripcionCorta(data.descripcionCorta || "");

        setFormData((prevState) => ({
          ...prevState,
          desc_corta: data.descripcionCorta || "",
          desc_larga: data.descripcion || "",
        }));
      } catch (error) {
        console.error("Error al obtener la información del producto:", error);
      }
    };

    fetchData();
  }, [ product.upcCode]);
*/


  const [formData, setFormData] = useState({
    mayorista: 2,
    sku_mayorista: product.ingramPartNumber,
    garantia: "6 meses",
    nombre: product.description,
    marca: product.vendorName,
    part_number: product.vendorPartNumber,
    dolarCambio: 812,
    margen: 1.2,
    usdPrice: product.price,
    compra_neto: product.price,
    venta_neto: product.price * 812,
    venta_bruto: undefined,
    IVA_venta: undefined,
    stock_publicado: parseInt((product.stock * 90) / 100),
    estado_publicacion: false,
    desc_corta: descripcionCorta,
    desc_larga: descripcion,
    eanupc_code: product.ingramPartNumber,
    weight: "",
    volume: "",
    length: "",
    width: "",
    height: "",
    dimension_unit: "",
    SKU_Mayorista: product.ingramPartNumber
  });

  useEffect(() => {
    const calculateSellingPrice = async () => {
      const payload = {
        valorUsd: product.price,
        peso: formData.weight,
        largo: formData.length,
        ancho: formData.width,
        alto: formData.height
      };
  
      try {
        const response = await axios.post(`https://api.hubapp.cl/api/variables/manejadores/calculateSellingPrice`, payload);
  
        if (response.data && response.data.sellingPrice) {
          setFormData(prevState => ({
            ...prevState,
            venta_bruto: response.data.sellingPrice,
            venta_neto: response.data.sellingPrice - response.data.taxes,
            IVA_venta: response.data.taxes
          }));
        }
      } catch (error) {
        console.error('Error al calcular el precio de venta:', error);
      }
    };
  
    calculateSellingPrice();
  }, [product.price, formData.weight, formData.length, formData.width, formData.height]); // dependencias del hook useEffect

  const handleClose = () => {
    navigate("/productos%20ingram");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('/api/products', {
        ...formData,
        mayoristaId: 2, // id de tecnoglobal
        sellerId: 3, // id de falabella
      });
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }

    try {
      //const response = await axios.get(`https://api.hubapp.cl/api/productos`);
      //const productos = response.data;
      /*const partNumberExists = productos.some(
        (producto) => producto.part_number === formData.part_number
      );*/
      //const isStockZero = formData.stock_publicado === 0;

      /*if (partNumberExists) {
        alert(
          "No puede ingresar el producto más de una vez. El producto seleccionado ya se encuentra agregado."
        );
        navigate("/productos%20ingram");
      } else if (isStockZero) {
        alert("No puede agregar productos que no tienen stock.");
        navigate("/productos%20ingram");
      } else {
        */
        const res = await fetch(`https://api.hubapp.cl/api/productos/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (res.status === 201) {
          alert("Producto agregado correctamente");
          navigate("/productos%20tecnoglobal")
        }
      }catch (error) {
        console.error(error);
    } 
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col items-center justify-center">
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/3 px-3">
              
              <TooltipComponent content="PESO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="number"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  placeholder="PESO"
                />
              </TooltipComponent>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <TooltipComponent content="VOLUMEN">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="number"
                  name="volume"
                  value={formData.volume}
                  onChange={handleChange}
                  placeholder="VOLUME"
                />
              </TooltipComponent>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <TooltipComponent content="LARGO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="number"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  placeholder="LARGO"
                />
              </TooltipComponent>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <TooltipComponent content="ANCHO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="number"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  placeholder="ANCHO"
                />
              </TooltipComponent>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <TooltipComponent content="ALTO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="number"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  placeholder="ALTO"
                />
              </TooltipComponent>
            </div>
            <div className="w-full md:w-1/3 px-3">
              <TooltipComponent content="UNIDAD DE MEDIDA">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  name="dimension_unit"
                  value={formData.dimension_unit}
                  onChange={handleChange}
                  placeholder="Unidad de medida"
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="EANUPC CODE">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  value={formData.eanupc_code || formData.part_number}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="NOMBRE DEL PRODUCTO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  value={product.vendorName + " " + product.description}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="MARCA DEL PRODUCTO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  value={product.vendorName}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="VENTA BRUTO">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  value={product.venta}
                  readOnly
                />
              </TooltipComponent>
            </div>
                  
            <div className="w-full px-3">
              <TooltipComponent content="PART NUMBER">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  value={product.vendorPartNumber}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="SKU MAYORISTA">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  name="sku"
                  value={product.ingramPartNumber}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="GARANTÍA">
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  type="text"
                  name="garantia"
                  value={formData.garantia}
                  readOnly
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="DESCRIPCIÓN CORTA DEL PRODUCTO">
                <textarea
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  required
                  name="desc_corta"
                  defaultValue={
                    "Nuestras bases de datos no encontraron contenido referente a este producto, deberá ingresarla manualmente al momento de publicar"
                  }
                  onChange={handleChange}
                  placeholder={
                       "Nuestras bases de datos no encontraron contenido referente a este producto, deberá ingresarla manualmente al momento de publicar"
                  }
                />
              </TooltipComponent>
            </div>
            <div className="w-full px-3">
              <TooltipComponent content="DESCRIPCIÓN LARGA DEL PRODUCTO">
                <textarea
                  className="block w-full px-4 py-2 text-gray-700 bg-gray-200 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="desc_larga"
                  defaultValuevalue={
                    "Nuestras bases de datos no encontraron contenido referente a este producto, deberá ingresar este campo manualmente al momento de publicar"
                  }
                  onChange={handleChange}
                  placeholder={
                    
                   "Nuestras bases de datos no encontraron contenido referente a este producto, deberá ingresarla manualmente al momento de publicar"
                  }
                />
              </TooltipComponent>
            </div>
            {/*REPETIR ESTO PARA TODOS LOS CAMPOS DE ENTRADA SI SE LLEGAN A AGREGAR MÁS*/}
          </div>

          <div className="flex justify-center mt-4">
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
            >
              Agregar
            </button>
            <button
              onClick={handleClose}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
            >
              Regresar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
