import React, { useState } from 'react';

export const AgregarProductoManual = () => {
    const [activeTab, setActiveTab] = useState('datos-producto');

    return (
        <div className="App">
            <div className="p-6">
                <nav>
                    <ul className="flex mb-4">
                        <li className="mr-6">
                            <button onClick={() => setActiveTab('datos-producto')} className="text-blue-500 hover:text-blue-800">Datos-Producto</button>
                        </li>
                        <li className="mr-6">
                            <button onClick={() => setActiveTab('seller')} className="text-blue-500 hover:text-blue-800">Seller</button>
                        </li>
                        <li className="mr-6">
                            <button onClick={() => setActiveTab('mayorista')} className="text-blue-500 hover:text-blue-800">Mayorista</button>
                        </li>
                    </ul>
                </nav>

                {activeTab === 'datos-producto' && <DatosProducto />}
                {activeTab === 'seller' && <Seller />}
                {activeTab === 'mayorista' && <Mayorista />}
            </div>
        </div>
    );
}

function DatosProducto() {
    return (
        <div>
            <form className="grid grid-cols-4 gap-4">
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Sku Interno" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Garantia" defaultValue="6 meses" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Sku Mayorista" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Nombre del producto" />
                {/*PUSE ESTE INPUT PARA MOSTRAR QUE AQUÍ IRÁ, CAMBIAR LUEGO POR LAS MARCAS MANEJADAS POR LOS SELLERS EN ESTE CASO FALABELLA. */}
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Marca del producto" /> 
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Part Number" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Dolar Cambio" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Margen Ganancia" defaultValue="2" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Precio en dolares" />
                <input className="border border-gray-400 p-2 rounded-md" placeholder="Precio de compra neto" />     
                
                
                
                
                {/* Continua con los demás inputs siguiendo el ejemplo... */}
            </form>
        </div>
    );
}

function Seller() {
    return (
        <div>
            <h2>Aquí va el contenido de Seller</h2>
        </div>
    );
}

function Mayorista() {
    return (
        <div>
            <h2>Aquí va el contenido de Mayorista</h2>
        </div>
    );
}
