import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsCurrencyDollar } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { Stacked, /*Button,*/ SparkLine } from "../components";
import { SparklineAreaData } from "../data/dummy";

const Ecommerce = () => {
  const [salesTotal, setSalesTotal] = useState(null);
  const [currentDollar, setCurrentDollar] = useState(null);
  const [previousDollar, setPreviousDollar] = useState(null);
  const [setVentasValidas] = useState(0);
  const [setClientesDiferentes] = useState(0);
  const [setOrdersCount] = useState(0);
  const [setRefundAmount] = useState(0);
  const [setRefundOrdersCount] = useState(0);

  const fetchDollarData = () => {
    axios
      .get("https://api.exchangerate-api.com/v4/latest/USD")
      .then((response) => {
        const rates = response.data.rates;
        setCurrentDollar(rates["CLP"]); // Reemplaza "COP" con el código de tu moneda local
      })
      .catch((error) => {
        console.error("Error fetching current dollar data:", error);
      });

    axios
      .get(
        "https://api.hubapp.cl/api/mayorista/tecnoglobal/products"
      )
      .then((response) => {
        const products = response.data;
        const yesterdayProduct = products.find((product) => {
          const productDate = new Date(product.localTimeStamp);
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          return productDate.toDateString() === yesterday.toDateString();
        });
        if (yesterdayProduct) {
          setPreviousDollar(yesterdayProduct.dolarTg);
        } else {
          setPreviousDollar(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching previous dollar data:", error);
      });
  };

  useEffect(() => {
    fetchDollarData();

    const interval = setInterval(fetchDollarData, 4 * 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetch(
      "https://api.hubapp.cl/api/seller/falabella/getmetrics"
    )
      .then((response) => response.json())
      .then((data) => {
        const salesTotalValue = data.SuccessResponse.Body[0].Metrics.find(
          (metric) => metric.MetricsType[0].StatisticsType[0] === "alltime"
        ).MetricsType[0].SalesTotal[0];

        setSalesTotal(salesTotalValue);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch(
      "https://api.hubapp.cl/api/seller/falabella/getorders"
    )
      .then((response) => response.json())
      .then((data) => {
        const orders = data.Orders;
        const deliveredOrders = orders.filter((order) =>
          order.Order.Statuses.some((status) => status.Status === "delivered")
        );
        const refundOrders = orders.filter(
          (order) =>
            !order.Order.Statuses.some(
              (status) => status.Status === "delivered"
            )
        );

        setVentasValidas(deliveredOrders.length);
        setOrdersCount(orders.length);
        setClientesDiferentes(
          new Set(orders.map((order) => order.Order.CustomerFirstName)).size
        );

        const refundAmount = refundOrders.reduce(
          (total, order) => total + parseFloat(order.Order.Price),
          0
        );
        setRefundAmount(refundAmount.toFixed(2));
        setRefundOrdersCount(refundOrders.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [setClientesDiferentes, setOrdersCount, setRefundAmount, setRefundOrdersCount, setVentasValidas]);

  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold text-gray-400">
                Ganancias Totales Falabella
              </p>
              {salesTotal ? (
                <div className="flex items-center">
                  <BsCurrencyDollar color="green" />
                  <p className="text-2xl color">{salesTotal}</p>
                  <span className="ml-2 text-xl text-green-500"></span>
                </div>
              ) : (
                <p className="text-2xl">Cargando...</p>
              )}
            </div>
            <button
              type="button"
              style={{ backgroundColor: "blue" }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full p-4"
            >
              <BsCurrencyDollar />
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-10 flex-wrap justify-center">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780">
          <div className="flex justify-between">
            <p className="font-semibold text-xl">Revenue Updates</p>
            <div className="flex items-center gap-4">
              <p className="flex items-center gap-2 text-gray-600 hover:drop-shadow-xl">
                <span>
                  <GoPrimitiveDot />
                </span>
                <span>Caida</span>
              </p>
              <p className="flex items-center gap-2 text-green-400 hover:drop-shadow-xl">
                <span>
                  <GoPrimitiveDot />
                </span>
                <span>Dolar</span>
              </p>
            </div>
          </div>
          <div className="mt-10 flex gap-10 flex-wrap justify-center">
            <div className="border-r-1 border-color m4 pr-10">
              <div>
                <p>
                  <span className="text-3xl font-semibold">
                    {currentDollar ? `$${currentDollar}` : "Cargando..."}
                  </span>
                  <span className="p-1.5 hover:drop-shadow-xl cursor-pointer rounded-full text-white bg-green-400 ml-3 text-xs">
                    23%
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Dolar Hoy</p>
              </div>
              <div className="mt-8">
                <p>
                  <span className="text-3xl font-semibold">
                    {previousDollar ? `$${previousDollar}` : "Cargando..."}
                  </span>
                </p>
                <p className="text-gray-500 mt-1">Dolar TecnoGlobal</p>
              </div>

              <div className="mt-5">
                <SparkLine
                  currentColor="green"
                  id="line-sparkline"
                  type="Line"
                  height="80px"
                  width="250px"
                  data={SparklineAreaData}
                  color="black"
                />
              </div>
            </div>
            <div>
              <Stacked width="320px" height="360px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
