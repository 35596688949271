import React, { useState, useEffect } from "react";
import axios from "axios";

export const UsuariosRegistrados = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://api.hubapp.cl/api/usuarios"
        );
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUsers();
  }, []);

  const deleteUser = async (id) => {
    try {
      await axios.delete(
        `https://api.hubapp.cl/api/usuarios/${id}`
      );
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const openEditModal = (user) => {
    setEditingUser(user);
  };

  const closeEditModal = () => {
    setEditingUser(null);
  };

  const updateUser = async (id, updatedUserData) => {
    try {
      await axios.put(
        `https://api.hubapp.cl/api/usuarios/${id}`,
        updatedUserData
      );

      // Actualiza la lista de usuarios después de la edición
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === id ? { ...user, ...updatedUserData } : user
        )
      );

      closeEditModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold mb-6">Usuarios Registrados</h1>
      <table className="min-w-full border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-gray-100 border-b">ID</th>
            <th className="px-4 py-2 bg-gray-100 border-b">Nombre</th>
            <th className="px-4 py-2 bg-gray-100 border-b">Email</th>
            <th className="px-4 py-2 bg-gray-100 border-b">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="px-4 py-2 border-b">{user.id}</td>
              <td className="px-4 py-2 border-b">{user.name}</td>
              <td className="px-4 py-2 border-b">{user.email}</td>
              <td className="px-4 py-2 border-b">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
                  onClick={() => openEditModal(user)}
                >
                  Editar
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => deleteUser(user.id)}
                >
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingUser && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal bg-black bg-opacity-80 border-2 border-green-500 rounded-lg p-4">
            <div className="modal-content">
              <h2 className="text-2xl font-bold mb-4 text-green-300">Editar Usuario</h2>
              <form>
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Nombre
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.name}
                    // Maneja el cambio en el nombre
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, name: e.target.value })
                    }
                  />
                </div>
                {/* Repite este patrón para otros campos de usuario */}
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Apellido
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.lastname}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        lastname: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Teléfono
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.telphone}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        telphone: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Email
                  </label>
                  <input
                    type="text"
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.email}
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, email: e.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.password}
                    onChange={(e) =>
                      setEditingUser({
                        ...editingUser,
                        password: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white text-sm font-bold mb-2">
                    Rol
                  </label>
                  <select
                    className="border rounded w-full py-2 px-3"
                    value={editingUser.role}
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, role: e.target.value })
                    }
                  >
                    <option value="USUARIO">USUARIO</option>
                    <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-actions">
              <button
                className="bg-green-300 hover:bg-green-400 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={() => updateUser(editingUser.id, editingUser)}
              >
                Editar
              </button>
              <button
                className="bg-red-300 hover:bg-red-400 text-white font-bold py-2 px-4 rounded"
                onClick={closeEditModal}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
