import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export const IngramProducts = () => {
  /* const [, setLoading] = useState(false); */
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  //const [searchTerm, setSearchTerm] = useState("");
  const [subCategoriaFilter, setSubCategoriaFilter] = useState("");
  const [marcaFilter, setMarcaFilter] = useState("");
  const [partNumberFilter, setPartNumberFilter] = useState("");
  const [skuFilter, setSkuFilter] = useState("");
  const [sortOrder, setSortOrder] = useState({
    column: "stock",
    order: "asc",
    stockOrder: "asc",
  });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (event) => {
    const { name, value } = event.target;
    if (name === "subCategoria") {
      setSubCategoriaFilter(value);
    } else if (name === "marca") {
      setMarcaFilter(value);
    } else if (name === "partNumber"){
      setPartNumberFilter(value);
    } else if (name === "sku"){
      setSkuFilter(value);
    }
  };

  const handlePriceSorting = () => {
    setSortOrder({
      column: "price",
      order:
        sortOrder.column === "price" && sortOrder.order === "asc"
          ? "desc"
          : "asc",
    });
  };

  useEffect(() => {
    axios
      .get(
        "https://api.hubapp.cl/api/mayorista/ingram/getfiltercatalog"
      )
      .then((response) => setProducts(response.data))
      .catch((error) => console.error(error));
  }, []);

  const navigate = useNavigate();

  const handleDesplegar = (product) => {
    navigate("/agregando-producto-ingram", { state: { product: product } });
  };

  /*const searchedProducts = async (term) => {
    setLoading(true);
    try {
      let response;
      if (term === "") {
        response = await axios.get(
          "https://api.hubapp.cl/api/mayorista/ingram/getfiltercatalog"
        );
      } else {
        response = await axios.get(
          "https://api.hubapp.cl/api/mayorista/ingram/getfiltercatalog"
        );
      }
      const filteredData = response.data.filter(
        (product) =>
          product.vendorPartNumber.toLowerCase().includes(term.toLowerCase()) ||
          product.ingramPartNumber.toLowerCase().includes(term.toLowerCase()) ||
          product.description.toLowerCase().includes(term.toLowerCase()) ||
          product.vendorName.toLowerCase().includes(term.toLowerCase())
      );
      setProducts(filteredData);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
*/

  const sortedProducts = [...products].sort((a, b) => {
    if (sortOrder.column === "stock") {
      return sortOrder.stockOrder === "asc"
        ? a.stock - b.stock
        : b.stock - a.stock;
    } else if (sortOrder.column === "price") {
      return sortOrder.order === "asc" ? a.price - b.price : b.price - a.price;
    }
    // default case: return zero
    return 0;
  });

  const handleStockSorting = () => {
    setSortOrder((prevState) => ({
      ...prevState,
      stockOrder: prevState.stockOrder === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex items-center justify-center mt-5 mb-5">
        <input
          type="text"
          placeholder="Filtro por sub categoría"
          name="subCategoria"
          value={subCategoriaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
         <input
          type="text"
          name="marca"
          placeholder="Filtrar por Marca"
          value={marcaFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
        <input
          type="text"
          name="partNumber"
          placeholder="Filtrar por PartNumber"
          value={partNumberFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
          <input
          type="text"
          name="sku"
          placeholder="Filtrar por SKU"
          value={skuFilter}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr
              className="rounded-lg text-sm font-medium text-gray-700 text-left"
              style={{ fontSize: "0.9674rem" }}
            >
              <th className="px-4 py-2 bg-gray-200">Acciones</th>
              <th
                className="px-4 py-2 bg-gray-200 cursor-pointer"
                onClick={handlePriceSorting}
              >
                Precio
              </th>
              <th
  className="px-4 py-2 bg-gray-200 cursor-pointer hover:bg-green-300 flex items-center"
  onClick={handleStockSorting}
>
  <span className="mr-2 px-2 py-3">Stock</span>
  <FontAwesomeIcon icon={faSortDown} className="w-4 fill-current text-green-600" />
</th>
              <th className="px-4 py-2 bg-gray-200">Part Number</th>
              <th className="px-4 py-2 bg-gray-200">Categoría</th>
              <th className="px-4 py-2 bg-gray-200">Descripción</th>

              <th className="px-4 py-2 bg-gray-200">Marca</th>

              <th className="px-4 py-2 bg-gray-200">Sub Categoría</th>
              <th className="px-4 py-2 bg-gray-200">Sku Ingram</th>
            </tr>
          </thead>
          <tbody>
          {sortedProducts
              .filter((product) => {
               return product.subCategory.toLowerCase().includes(subCategoriaFilter.toLowerCase()) && 
               product.vendorName.toLowerCase().includes(marcaFilter.toLowerCase()) && 
               product.vendorPartNumber.toLowerCase().includes(partNumberFilter.toLowerCase()) &&
               product.ingramPartNumber.toLowerCase().includes(skuFilter.toLowerCase())
              })
              .slice(
                currentPage * itemsPerPage,
                (currentPage + 1) * itemsPerPage
              )
              .map((product) => (
                <tr
                  key={product.codigoTg}
                  className="text-sm font-normal text-gray-700"
                >
                  <button
                    className="px-4 py-4 border-2 border-green-300 text-sm text-white bg-green-600 hover:bg-green-700"
                    onClick={() => handleDesplegar(product)}
                  >
                    Desplegar
                  </button>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.price}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.stock}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.vendorPartNumber}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.category}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.description}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.vendorName}
                  </td>

                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.subCategory}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                    {product.ingramPartNumber}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        breakLabel={"..."}
        pageCount={Math.ceil(products.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination flex justify-center space-x-2"}
        pageClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-500 hover:text-white duration-500"
        }
        previousClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        nextClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        breakClassName={"break px-3 py-2 border rounded text-sm cursor-pointer"}
        activeClassName={"active bg-green-500 text-white"}
      />
    </div>
  );
};
