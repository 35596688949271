import React, { useState } from "react";
import axios from 'axios';
import emailjs from 'emailjs-com';

export const CrearUsuario = () => {
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        telphone: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: ''
    });

    const [passwordError, setPasswordError] = useState('');

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleFormSubmit = async (e) => {
      e.preventDefault();

      if (formData.password !== formData.confirmPassword){
        setPasswordError('Las contraseñas no coinciden');
        return;
      }
    
      try {
        const response = await axios.post('https://api.hubapp.cl/api/usuarios', formData);
        console.log(response.data);
        alert("Usuario creado correctamente!");
        console.log("Email Ingresado: ", formData.email);
    
        const templateParams = {
          to_name: formData.name,
          to_email: formData.email,
          reply_to: formData.email,
          link: "https://orange-rock-07a2a220f.3.azurestaticapps.net/"
        };
  
        emailjs.send('service_qa1qmf1', 'template_y4wommr', templateParams, '2mo79mJGK1G3tYkCb')
          .then((response) => {
            console.log('Email successfully sent!', response.status, response.text);
          })
          .catch((err) => {
            console.error('Failed to send email. Error: ', err);
          });
  
      } catch (error) {
        console.error(error);
        alert("Error al crear el usuario, revise la consola para mayor información...")
      }
    }

    const clearFields = () => {
      const inputFields = document.querySelectorAll('input');
      inputFields.forEach((input) => {
        input.value='';
      });
    }

    return (
        <div className="min--screen bg-gray-100 py-6 flex flex-col justify-center xl:py-10">
          <div className="relative py-3 sm:max-w-xl sm:mx-auto">
            <div className="relative px-1 py-10 bg-white mx-16 md:mx-0 shadow rounded-3xl sm:p-10">
              <div className="max-w-xl mx-auto">
                <div className="flex items-center space-x-5">
                  <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                    <h2 className="leading-relaxed">Formulario de Registro</h2>
                  </div>
                </div>
                <div className="divide-y divide-gray-200">
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 grid grid-cols-3 gap-1">
                    <div className="flex flex-col py-4">
                      <label className="leading-loose">Primer Nombre</label>
                      <input
                        required
                        type="text"
                        name="name"
                        onChange={handleInputChange}
                        placeholder=""
                        className="input-box border-2 border-green-800 px-4 py-2"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="leading-loose">Apellido Paterno</label>
                      <input
                        required
                        type="text"
                        name="lastname"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="leading-loose">Teléfono</label>
                      <input
                        required
                        type="text"
                        name="telphone"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="leading-loose">Email</label>
                      <input
                        required
                        type="email"
                        name="email"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                        placeholder="tunombre@megabits.cl"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="leading-loose">Password</label>
                      <input
                        required
                        type="password"
                        name="password"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="leading-loose">Repetir Password</label>
                      <input
                        required
                        type="password"
                        name="confirmPassword"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                      />
                    </div>
                    {passwordError && (
                      <div className="col-span-3 text-red-500">{passwordError}</div>
                    )}
                    <div className="flex flex-col">
                      <label className="leading-loose">Tipo de Usuario</label>
                      <select
                        required
                        name="role"
                        onChange={handleInputChange}
                        className="input-box border-2 border-green-800 px-4 py-2"
                      >
                        <option value="">Selecciona un tipo</option>
                        <option value="ADMINISTRADOR">ADMINISTRADOR</option>
                        <option value="USUARIO">USUARIO</option>
                      </select>
                    </div>
                  </div>
                  <div className="pt-4 flex items-center space-x-4">
                    <button onClick={handleFormSubmit} type="submit" className="flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none bg-green-600 hover:bg-green-500">
                      Registrar
                    </button>
                    <button className="flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none bg-red-600 hover:bg-red-500" onClick={clearFields}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      );
      
      
}
