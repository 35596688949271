import React, { useState } from 'react';
import axios from 'axios';

export const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.get(
        'https://api.hubapp.cl/api/usuarios'
      );
      const users = response.data;
      const user = users.find(
        (user) => user.email === username && user.password === password
      );

      if (user) {
        setAlertMessage('Usuario encontrado en la base de datos');
        onLoginSuccess(user);
      } else {
        setAlertMessage(
          'Usuario no encontrado en el sistema, asegúrate de ingresar los datos correctos o contacta con soporte'
        );
      }
    } catch (error) {
      console.error(error);
      setAlertMessage('Ocurrió un error al intentar iniciar sesión');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-green-15">
      <div className="flex flex-col justify-between p-10 bg-white rounded-lg shadow-xl shadow-gray w-96 m-1">
        <div className="bg-white"> {/* Agrega la clase 'bg-white' para establecer el fondo blanco */}
          <h2 className="mb-5 text-3xl font-bold text-green-900">Iniciar Sesión</h2>
          <input
            className="mb-4 p-2 bg-green-100 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-400"
            type="text"
            placeholder="Nombre de usuario"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            className="mb-4 p-2 bg-green-100 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-green-400"
            type="password"
            placeholder="Contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="mb-2 w-full py-2 text-white bg-green-500 rounded-md hover:bg-green-600 transition duration-200"
            onClick={handleLogin}
          >
            Login
          </button>
          <button
            className="w-full py-2 text-white bg-green-300 rounded-md hover:bg-green-400 transition duration-200"
          >
            Solicitar Acceso
          </button>
          {alertMessage && (
            <div className="mt-4 p-3 text-white bg-red-700 rounded-md">
              {alertMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
