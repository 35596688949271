import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export const SellersShow = () => {
  const [sellers, setSellers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingSeller, setEditingSeller] = useState(null);
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [web, setWeb] = useState("");
  const [email_general, setEmailGeneral] = useState("");

  useEffect(() => {
    axios
      .get("https://api.hubapp.cl/api/sellers")
      .then((response) => {
        setSellers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEliminarSeller = (id) => {
    axios
      .delete(`https://api.hubapp.cl/api/sellers/${id}`)
      .then(() => {
        setSellers(sellers.filter((seller) => seller.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditarSeller = (seller) => {
    setEditingSeller(seller);
    setShowModal(true);
    setNombre(seller.nombre);
    setTelefono(seller.telefono);
    setDireccion(seller.direccion);
    setWeb(seller.web);
    setEmailGeneral(seller.email_general);
  };

  const handleCerrarModal = () => {
    setShowModal(false);
  };

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
    setEditingSeller({
      ...editingSeller,
      nombre: event.target.value,
    });
  };

  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    setEditingSeller({
      ...editingSeller,
      telefono: event.target.value,
    });
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
    setEditingSeller({
      ...editingSeller,
      direccion: event.target.value,
    });
  };

  const handleWebChange = (event) => {
    setWeb(event.target.value);
    setEditingSeller({
      ...editingSeller,
      web: event.target.value,
    });
  };

  const handleEmailGeneralChange = (event) => {
    setEmailGeneral(event.target.value);
    setEditingSeller({
      ...editingSeller,
      email_general: event.target.value,
    });
  };

  const handleGuardarCambios = () => {
    axios
      .put(
        `https://api.hubapp.cl/api/sellers/${editingSeller.id}`,
        editingSeller
      )
      .then((response) => {
        const index = sellers.findIndex(
          (seller) => seller.id === editingSeller.id
        );
        sellers[index] = response.data;
        setSellers([...sellers]);
        handleCerrarModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container mx-auto px-4">
      <div className="my-5">
        <table className="table-auto w-full">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">Nombre</th>
              <th className="px-4 py-2">Teléfono</th>
              <th className="px-4 py-2">Dirección</th>
              <th className="px-4 py-2">Sitio Web</th>
              <th className="px-4 py-2">Email General</th>
              <th className="px-4 py-2">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {sellers.map((seller) => (
              <tr key={seller.id} className="text-center">
                <td className="border px-4 py-2">{seller.nombre}</td>
                <td className="border px-4 py-2">{seller.telefono}</td>
                <td className="border px-4 py-2">{seller.direccion}</td>
                <td className="border px-4 py-2">{seller.web}</td>
                <td className="border px-4 py-2">{seller.email_general}</td>
                <td className="border px-4 py-2">
                  <button
                    className="mx-1 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded duration-500"
                    onClick={() => handleEditarSeller(seller)}
                  >
                    Editar
                  </button>
                  <button
                    className="mx-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded duration-500"
                    onClick={() => handleEliminarSeller(seller.id)}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={handleCerrarModal}
          static
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <div className="min-h-screen px-4 text-center flex items-center justify-center">
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              onClick={(e) => e.stopPropagation()}
            >
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Editar Seller
              </Dialog.Title>

              <div className="mt-2">
                <input
                  type="text"
                  name="nombre"
                  value={nombre}
                  onChange={handleNombreChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Nombre"
                />
                <input
                  type="text"
                  name="telefono"
                  value={telefono}
                  onChange={handleTelefonoChange}
                  className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Teléfono"
                />
                <input
                  type="text"
                  name="direccion"
                  value={direccion}
                  onChange={handleDireccionChange}
                  className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Dirección"
                />
                <input
                  type="text"
                  name="web"
                  value={web}
                  onChange={handleWebChange}
                  className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Sitio Web"
                />
                <input
                  type="email"
                  name="email_general"
                  value={email_general}
                  onChange={handleEmailGeneralChange}
                  className="mt-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Email General"
                />
              </div>
              <div className="mt-4 flex items-center justify-end">
                <button
                  type="button"
                  className="mr-3 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleGuardarCambios}
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleCerrarModal}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
