import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import axios from "axios";


export const PublicandoProducto = () => {
  const location = useLocation();
  const product = location.state.productToPublish;
  const [categorias, setCategorias] = useState([]);
  const [brands, setBrands] = useState([]);
  const [productId, setProductId] = useState(product.eanupc_code);
  const [SellerSku, setSellerSku] = useState(product.sku_interno);
  const [name, setName] = useState(product.nombre);
  const [variation, setVariation] = useState("...");
  const [/*primaryCategory*/, setPrimaryCategory] = useState("");
  const [description, setDescription] = useState(product.desc_larga);
  const [brand, setBrand] = useState([]);
  const [conditionType, setConditionType] = useState("Nuevo");
  const [condition, /*setCondition*/] = useState("new");
  const [shipmentType, setShipmentType] = useState("DropShipping");
  //const partNumberValue = product.part_number;
  const [partNumber] = useState(product.part_number);
  const [packageWeight, setPackageWeight] = useState(product.weight);
  const [packageHeight, setPackageHeight] = useState(product.height);
  const [packageLength, setPackageLength] = useState(product.length);
  const [packageWidth, setPackageWidth] = useState(product.width);
  const [price, setPrice] = useState(product.venta_bruto);
  const [stock, setStock] = useState(product.stock_publicado);
  const [subcategorias, setSubcategorias] = useState([]);
  const [selectedSubcategoria, setSelectedSubcategoria] = useState(null);
  //const [showConectividadConexion, setShowConectividadConexion] = useState(false);
  const [ConectividadConexion, setConectividadConexion] = useState("");
  const [atribEspecial, setAtribEspecial] = useState(null);
  const [TipoDeImpresora, setTipoDeImpresora] = useState("");
  const [CalidadDeImpresion, setCalidadDeImpresion] = useState("");
  const [TamanoDeLaPantalla, setTamanoDeLaPantalla] = useState("");
  const [ResolucionDePantalla, setResolucionDePantalla] = useState("");
  const [Procesador, setProcesador] = useState();
  const [NucleosDelProcesador, setNucleosDelProcesador] = useState();
  const [TipoDeAuricular, setTipoDeAuricular] = useState();
  const [CamaraPosterior, setCamaraPosterior] = useState();
  const [IncluyeCargador, setIncluyeCargador] = useState();
  const [Generacion, setGeneracion] = useState();
  const [CapacidadDeAlmacenamiento, setCapacidadDeAlmacenamiento] = useState();
  const [categoriasObtenidas, /*setCategoriasObtenidas*/] = useState();

 

  //Cambiar a /:find
  async function obtenerMarcas() {
    const respuesta = await fetch(`https://api.hubapp.cl/api/seller/falabella/getAllBrands`);
    const datos = await respuesta.json();
  
    return datos.map(brand => ({
      value: brand.Brand.Name,
      label: brand.Brand.Name,
    }));
  }

  useEffect(() => {
    if (subcategorias.length > 0) {
      setSelectedSubcategoria(subcategorias[0].value); // Configurar la primera subcategoría como valor inicial
    } else {
      setSelectedSubcategoria(null); // Si no hay subcategorías, establecer el valor en null para ocultar el segundo select
    }
  }, [subcategorias]);

  const handleIncluyeCargadorChange = (selectedOption) => {
    setIncluyeCargador(selectedOption.value);
  }

  const handleGeneracionChange = (selectedOption) => {
    setGeneracion(selectedOption.value);
  }

  const handleCapacidadDeAlmacenamientoChange = (selectedOption) => {
    setCapacidadDeAlmacenamiento(selectedOption.value);
  }

  const handleCamaraPosteriorChange = (selectedOption) => {
    setCamaraPosterior(selectedOption.value);
  }

  const handleProcesadorChange = (selectedOption) => {
    setProcesador(selectedOption.value);
  }

  const handleNucleosDelProcesadorChange = (selectedOption) => {
    setNucleosDelProcesador(selectedOption.value);
  }

  const handleTipoDeAuricularChange = (selectedOption) => {
    setTipoDeAuricular(selectedOption.value);
  }

  const handleConectividadConexionChange = (selectedOption) => {
    setConectividadConexion(selectedOption.value);
  };

  const handleTipoDeImpresoraChange = (selectedOption) => {
    setTipoDeImpresora(selectedOption.value);
  };

  const handleCalidadDeImpresionChange = (selectedOption) => {
    setCalidadDeImpresion(selectedOption.value);
  }

  const handleResolucionDePantallaChange = (selectedOption) => {
    setResolucionDePantalla(selectedOption.value);
  }

  const handleTamanoDeLaPantalla = (event) => {
    const valorInput = event.target.value;
    setTamanoDeLaPantalla(valorInput);
  }

  const handleCancelarClick = () => {
    window.location.href = "/productos";
  };

  const extractCategories = (category, parentName = '') => {
    let result = [
      {
        value: category.CategoryId,
        label: category.Name,
        parent: parentName, // Agregar el nombre de la categoría padre
      },
    ];
  
    if (category.Children && Array.isArray(category.Children.Category)) {
      category.Children.Category.forEach((childCategory) => {
        result = [
          ...result,
          ...extractCategories(childCategory, category.Name), // Pasar el nombre de la categoría padre como argumento recursivo
        ];
      });
    }
  
    return result;
  };

  useEffect(() => {
    obtenerMarcas().then(setBrands);
  }, []);

  async function obtenerCategorias() {
    const respuesta = await fetch(
      `https://api.hubapp.cl/api/seller/falabella/getAllCategories`
    );
    const datos = await respuesta.json();
  
    // Filtramos para obtener solo las categorías padre
    const categoriasPadre = datos.filter(
      (categoria) => categoria.Children && categoria.Children.Category
    );
  
    // Mapeamos las categorías al formato {value, label}
    return categoriasPadre.map(categoria => ({
      value: categoria.CategoryId,
      label: categoria.Name
    }));
  }

  useEffect(() => {
    obtenerCategorias().then((categoriasObtenidas) => {
      console.log(
        "Categorías establecidas en el estado: ",
        categoriasObtenidas
      ); // Comprueba si las categorías se están estableciendo en el estado
      setCategorias(categoriasObtenidas);
    });
  }, [categoriasObtenidas]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const xmlData = {
      productInternalID: product.id,
      //sellerId: 3,
      productId: productId,
      sellerSKU: SellerSku,
      name: name,
      variation: variation,
      primaryCategory: selectedSubcategoria,
      description: description,
      brand: brand || 'defaultBrand',
      conditionType: conditionType,
      condition: condition,
      shipmentType: shipmentType,
      packageWeight: packageWeight,
      packageHeight: packageHeight,
      packageLength: packageLength,
      packageWidth: packageWidth,
      price: price,
      stock: stock,
      // Add more fields as needed
    };

    
    if (atribEspecial === "2653"){
      xmlData.details = {
        ...xmlData.details,
        ConectividadConexion: ConectividadConexion,
     };
   };

   if (atribEspecial === "1442"){
    xmlData.details = {
      ...xmlData.details,
      ConectividadConexion: ConectividadConexion,
    }
   }

   if (atribEspecial === "3249"){
    xmlData.details = {
      ...xmlData.details,
      Procesador: Procesador,
      CamaraPosterior: CamaraPosterior,
    }
   }

   if (atribEspecial === "888"){
    xmlData.details = {
      ...xmlData.details,
      TipoDeImpresora: TipoDeImpresora,
      CalidadDeImpresion: CalidadDeImpresion
    }
   }

   if (atribEspecial === "483"){
    xmlData.details = {
      ...xmlData.details,
      TamanoDeLaPantalla: TamanoDeLaPantalla,
      ResolucionDePantalla: ResolucionDePantalla,
    }
   }

   if(atribEspecial === "432"){
    xmlData.details = {
      ...xmlData.details,
      Procesador: Procesador,
      NucleosDelProcesador: NucleosDelProcesador,
    }
   }

   if(atribEspecial === "837"){
    xmlData.details = {
      ...xmlData.details,
      Procesador: Procesador,
      NucleosDelProcesador: NucleosDelProcesador,
    }
   }

   if(atribEspecial === "954"){
    xmlData.details = {
      ...xmlData.details,
      TipoDeAuricular: TipoDeAuricular,
      ConectividadConexion: ConectividadConexion,
    }
   }

   if(atribEspecial === "3056"){
    xmlData.details = {
      ...xmlData.details,
      ResolucionDePantalla: ResolucionDePantalla,
    }
   }

   if(atribEspecial === "2769"){
    xmlData.details = {
      ...xmlData.details,
      ConectividadConexion: ConectividadConexion,
    }
   }

   if(atribEspecial === "2668"){
    xmlData.details = {
      ...xmlData.details,
      IncluyeCargador: IncluyeCargador,
      Generacion: Generacion,
      CapacidadDeAlmacenamiento: CapacidadDeAlmacenamiento,
      CamaraPosterior: CamaraPosterior,
    }
   }

    console.log(xmlData.sellerSku)
    console.log(SellerSku)

   try {
      console.log(xmlData.sellerSku)
      const response = await axios.post(`https://api.hubapp.cl/api/seller/falabella/setproduct`, xmlData);
      console.log(response.data);
    /*  const sellerData = {
        SKU_Seller: SellerSku,
        sellerId: "3"
      };*/
      alert("Producto publicado correctamente!");
    } catch (error) {
      console.error(error);
      alert("No se pudo publicar el producto, revisa la consola para mayor información")
    }
  };

  // Other handle functions can be created similarly
const handleProductIdChange = (e) => {
  setProductId(e.target.value);
}
 
  useEffect(() => {
    if (product) {
      setSellerSku(product.sku_interno);
    }
  }, [product]);
  
  useEffect(() => {
    if (SellerSku) {
      // Aquí puedes hacer algo con el valor actualizado de SellerSku
      console.log(SellerSku);
      // Puedes llamar a tu función de API aquí
    }
  }, [SellerSku]);

  const handleSellerSkuChange = (e) => {
    setSellerSku(e.target.value);
  };
  const handleNameChange = (e) => setName(e.target.value);
  const handleVariationChange = (e) => setVariation(e.target.value);
  const handlePrimaryCategory = async (selectedOption) => {
    const categoryId = selectedOption.value;
    setPrimaryCategory(categoryId);
  
    try {
      const response = await axios.get(
        `https://api.hubapp.cl/api/seller/falabella/secondCategoriesFalabella/${categoryId}`
      );
  
      let subcategoriasObtenidas = [];
      if (Array.isArray(response.data)) {
        response.data.forEach((subcategoria) => {
          subcategoriasObtenidas = [
            ...subcategoriasObtenidas,
            ...extractCategories(subcategoria),
          ];
        });
      }
  
      // Ordenar las subcategorías alfabéticamente por nombre
      subcategoriasObtenidas.sort((a, b) =>
        a.label.localeCompare(b.label, 'es', { sensitivity: 'base' })
      );
  
      setSubcategorias(subcategoriasObtenidas);
      console.log(subcategoriasObtenidas);
    } catch (error) {
      console.error(error);
      setSubcategorias([]);
    }
  };
  

  const handleSubcategoriaChange = (selectedOption) => {
    setSelectedSubcategoria(selectedOption.value);
    setAtribEspecial(selectedOption.value);
  };

  useEffect(() => {
    console.log("Atributo Especial: ", atribEspecial);
  }, [atribEspecial]);

  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleBrandChange = selectedOption => {
    setBrand(selectedOption.value);
  };
  const handleCondition = (e) => setConditionType(e.target.value);
  const handleShipmentType = (e) => setShipmentType(e.target.value);
  const handlePackageWeight = (e) => setPackageWeight(e.target.value);
  const handlePackageHeight = (e) => setPackageHeight(e.target.value);
  const handlePackageLength = (e) => setPackageLength(e.target.value);
  const handlePackageWidth = (e) => setPackageWidth(e.target.value);
  const handlePrice = (e) => setPrice(e.target.value);
  const handleStock = (e) => setStock(e.target.value);

  return (
    <div className="container mx-auto p-6">
      <form onSubmit={handleSubmit}>
        <h1 className="text-3xl font-semibold mb-6 text-gray-700">
          Publicando Producto
        </h1>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">Product Id</label>
          <input
            type="text"
            className="px-3 py-2 border border-gray-300 rounded-md"
            defaultValue={productId}
            onChange={handleProductIdChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">Part Number</label>
          <input 
            type="text"
            className="px-3 py-2 border border-gray-300 rounded-md"
            defaultValue={partNumber}
            disabled
            />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            SKU Falabella
          </label>
          <input
            type="text"
            defaultValue={SellerSku}
            disabled
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleSellerSkuChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Nombre Producto
          </label>
          <input
            type="text"
            value={name}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleNameChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">Variante</label>
          <input
            type="text"
            className="px-3 py-2 border border-gray-300 rounded-md"
            defaultValue={variation}
            onChange={handleVariationChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Categoría Primaria
          </label>
          <Select 
  options={categorias}
  onChange={handlePrimaryCategory}
  isSearchable 
  className="border border-gray-300 rounded-md"
/>
{subcategorias.length > 0 && (
  <div className="flex flex-col mb-4">
    <label className="mb-2 font-semibold text-gray-700">Subcategoría</label>
    <Select
      options={subcategorias}
      onChange={handleSubcategoriaChange}
      isSearchable
      formatOptionLabel={({ label, parent }) =>
        parent ? (
          <span>
            <strong>{parent}</strong>
            <br />
            {label}
          </span>
        ) : (
          label
        )
      }
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value}
      className="border border-gray-300 rounded-md"
    />
  </div>
)}
{/* Mostrar el segundo select solo cuando la categoría seleccionada sea "Mouse | dispositivos de punteros para computadores" */}
{(atribEspecial === 483 || selectedSubcategoria === "483") && (
  <div className="flex flex-col mb-4">
    <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
    <input
            type="text"
            placeholder='Tamaño de pantalla, representado en pulgadas, ej: 12"'
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleTamanoDeLaPantalla}
    />
    <Select placeholder="Resolución De Pantalla"
              options={[
                { value: "SD (640 x 480)", label: "SD (640 x 480)" },
                { value: "QHD (960 x 540)", label: "QHD (960 x 540)" },
                { value: "HD (1.280 x 720)", label: "HD (1.280 x 720)" },
                { value: "FHD (1.920 x 1.080)", label: "FHD (1.920 x 1.080)" },
                { value: "QHD 2K (2.560 x 1.440)", label: "QHD 2K (2.560 x 1.440)" },
                { value: "UHD (3.840 x 2.160)", label: "UHD (3.840 x 2.160)" },
                { value: "UHD 8K (7.680 x 4.320)", label: "UHD 8K (7.680 x 4.320)" },
                { value: "QVGA (320 x 240)", label: "QVGA (320 x 240)" },
                { value: "VGA (640 x 480)", label: "VGA (640 x 480)" },
              ]}
              onChange={handleResolucionDePantallaChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
  </div>
)}
{(atribEspecial === 3056 || selectedSubcategoria === "3056") && (
  <div className="flex flex-col mb-4">
    <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
    <Select placeholder="Resolución De Pantalla"
              options={[
                { value: "SD (640 x 480)", label: "SD (640 x 480)" },
                { value: "QHD (960 x 540)", label: "QHD (960 x 540)" },
                { value: "HD (1.280 x 720)", label: "HD (1.280 x 720)" },
                { value: "FHD (1.920 x 1.080)", label: "FHD (1.920 x 1.080)" },
                { value: "QHD 2K (2.560 x 1.440)", label: "QHD 2K (2.560 x 1.440)" },
                { value: "UHD (3.840 x 2.160)", label: "UHD (3.840 x 2.160)" },
                { value: "UHD 8K (7.680 x 4.320)", label: "UHD 8K (7.680 x 4.320)" },
                { value: "QVGA (320 x 240)", label: "QVGA (320 x 240)" },
                { value: "VGA (640 x 480)", label: "VGA (640 x 480)" },
              ]}
              onChange={handleResolucionDePantallaChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
  </div>
)}
{(atribEspecial === 2653 || selectedSubcategoria === "2653") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
            <Select
              options={[
                { value: "Alámbrico", label: "Alámbrico" },
                { value: "Wireless", label: "Wireless" },
                { value: "Bluetooth", label: "Bluetooth" },
                { value: "USB", label: "USB" },
                { value: "USB-C", label: "USB-C" },
              ]}
              onChange={handleConectividadConexionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
        {(atribEspecial === 1442 || selectedSubcategoria === "1442") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Conectividad Conexión</label>
            <Select
              options={[
                { value: "Alámbrico", label: "Alámbrico" },
                { value: "Analógico UHF", label: "Analógico UHF"},
                { value: "Android auto", label: "Android auto"},
                { value: "Apple carplay", label: "Apple carplay"},
                { value: "Auxiliar 3.5mm", label: "Auxiliar 3.5mm"},
                { value: "Cableado", label: "Cableado"},
                { value: "Cableado digital", label: "Cableado digital"},
                { value: "Chrome OS", label: "Chrome OS"},
                { value: "Cualquier dispositivo", label: "Cualquier dispositivo"},
                { value: "HDMI", label: "HDMI"},
                { value: "Incorporado", label: "Incorporado"},
                { value: "Infrarrojo", label: "Infrarrojo"},
                { value: "MiFi master G", label: "MiFi master G"},
                { value: "Micro USB", label: "Micro USB"},
                { value: "NFC", label: "NFC"},
                { value: "Otro", label: "Otro"},
                { value: "PS/2", label: "PS/2"},
                { value: "Para auto", label: "Para auto"},
                { value: "Pared", label: "Pared"},
                { value: "Pared/auto", label: "Pared/auto"},
                { value: "Portable", label: "Portable"},
                { value: "Solar", label: "Solar"},
                { value: "WF wireless", label: "WF wireless"},
                { value: "Wifi", label: "Wifi"},
                { value: "Wifi 6", label: "Wifi 6"}, 
                { value: "UHF digital", label: "UHF digital"},
                { value: "Wireless", label: "Wireless" },
                { value: "Bluetooth", label: "Bluetooth" },
                { value: "USB", label: "USB" },
                { value: "USB-C", label: "USB-C" },
              ]}
              onChange={handleConectividadConexionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
        {(atribEspecial === 2769 || selectedSubcategoria === "2769") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Conectividad Conexión</label>
            <Select
              options={[
                { value: "Alámbrico", label: "Alámbrico" },
                { value: "Analógico UHF", label: "Analógico UHF"},
                { value: "Android auto", label: "Android auto"},
                { value: "Apple carplay", label: "Apple carplay"},
                { value: "Auxiliar 3.5mm", label: "Auxiliar 3.5mm"},
                { value: "Cableado", label: "Cableado"},
                { value: "Cableado digital", label: "Cableado digital"},
                { value: "Chrome OS", label: "Chrome OS"},
                { value: "Cualquier dispositivo", label: "Cualquier dispositivo"},
                { value: "HDMI", label: "HDMI"},
                { value: "Incorporado", label: "Incorporado"},
                { value: "Infrarrojo", label: "Infrarrojo"},
                { value: "MiFi master G", label: "MiFi master G"},
                { value: "Micro USB", label: "Micro USB"},
                { value: "NFC", label: "NFC"},
                { value: "Otro", label: "Otro"},
                { value: "PS/2", label: "PS/2"},
                { value: "Para auto", label: "Para auto"},
                { value: "Pared", label: "Pared"},
                { value: "Pared/auto", label: "Pared/auto"},
                { value: "Portable", label: "Portable"},
                { value: "Solar", label: "Solar"},
                { value: "WF wireless", label: "WF wireless"},
                { value: "Wifi", label: "Wifi"},
                { value: "Wifi 6", label: "Wifi 6"}, 
                { value: "UHF digital", label: "UHF digital"},
                { value: "Wireless", label: "Wireless" },
                { value: "Bluetooth", label: "Bluetooth" },
                { value: "USB", label: "USB" },
                { value: "USB-C", label: "USB-C" },
              ]}
              onChange={handleConectividadConexionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
                {(atribEspecial === 954 || selectedSubcategoria === "954") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Conectividad Conexión</label>
            <Select
              options={[
                { value: "Alámbrico", label: "Alámbrico" },
                { value: "Analógico UHF", label: "Analógico UHF"},
                { value: "Android auto", label: "Android auto"},
                { value: "Apple carplay", label: "Apple carplay"},
                { value: "Auxiliar 3.5mm", label: "Auxiliar 3.5mm"},
                { value: "Cableado", label: "Cableado"},
                { value: "Cableado digital", label: "Cableado digital"},
                { value: "Chrome OS", label: "Chrome OS"},
                { value: "Cualquier dispositivo", label: "Cualquier dispositivo"},
                { value: "HDMI", label: "HDMI"},
                { value: "Incorporado", label: "Incorporado"},
                { value: "Infrarrojo", label: "Infrarrojo"},
                { value: "MiFi master G", label: "MiFi master G"},
                { value: "Micro USB", label: "Micro USB"},
                { value: "NFC", label: "NFC"},
                { value: "Otro", label: "Otro"},
                { value: "PS/2", label: "PS/2"},
                { value: "Para auto", label: "Para auto"},
                { value: "Pared", label: "Pared"},
                { value: "Pared/auto", label: "Pared/auto"},
                { value: "Portable", label: "Portable"},
                { value: "Solar", label: "Solar"},
                { value: "WF wireless", label: "WF wireless"},
                { value: "Wifi", label: "Wifi"},
                { value: "Wifi 6", label: "Wifi 6"}, 
                { value: "UHF digital", label: "UHF digital"},
                { value: "Wireless", label: "Wireless" },
                { value: "Bluetooth", label: "Bluetooth" },
                { value: "USB", label: "USB" },
                { value: "USB-C", label: "USB-C" },
              ]}
              onChange={handleConectividadConexionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Tipo de Auricular"
              options={[
                { value: "Auriculares deportivos", label: "Auriculares deportivos" },
                { value: "Auriculares in ear", label: "Auriculares in ear" },
                { value: "Micrófonos Gamer", label: "Micrófonos Gamer" },
                { value: "Auriculares on ear", label: "Auriculares on ear" },
                { value: "Auriculares over ear", label: "Auriculares over ear" },
              ]}
              onChange={handleTipoDeAuricularChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            
          </div>
        )}
        {(atribEspecial === 888 || selectedSubcategoria === "888") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
            <Select placeholder="Tipo de impresora"
              options={[
                { value: "Combo", label: "Combo" },
                { value: "Escaners", label: "Escaners" },
                { value: "Fax", label: "Fax" },
                { value: "Impresora 3D", label: "Impresora 3D" },
                { value: "Impresora láser", label: "Impresora láser" },
                { value: "Impresoras estándar", label: "Impresoras estándar"},
                { value: "Impresoras fotográficas", label: "Impresoras fotográficas"},
                { value: "Impresoras multifunción", label:"Impreosras multifunción"},
                { value: "Plotters", label: "Plotters"},
                { value: "Printers portátiles", label: "Printers Portátiles"},
              ]}
              onChange={handleTipoDeImpresoraChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Calidad de impresión"
              options={[
                { value: "Alta resolución", label: "Alta resolución" },
                { value: "Baja resolución", label: "Baja resolución" },
                { value: "Media resolución", label: "Media resolución" },
              ]}
              onChange={handleCalidadDeImpresionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
        {(atribEspecial === 432 || selectedSubcategoria === "432") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
            <Select
              options={[
                { value: "Allwinner serie A", label: "Allwinner serie A" },
                { value: "AMD a10", label: "AMD a10" },
                { value: "AMD a12", label: "AMD a12" },
                { value: "AMD a4", label: "AMD a4" },
                { value: "AMD a6", label: "AMD a6" },
                { value: "AMD a9", label: "AMD a9" },
                { value: "AMD a-series", label: "AMD a-series" },
                { value: "AMD athlon", label: "AMD athlon" },
                { value: "AMD c-series", label: "AMD c-series" },
                { value: "AMD e2", label: "AMD e2" },
                { value: "AMD epyc", label: "AMD epyc" },
                { value: "AMD e-series", label: "AMD e-series" },
                { value: "AMD fx-series", label: "AMD fx-series" },
                { value: "AMD opteron", label: "AMD opteron" },
                { value: "AMD phenom", label: "AMD phenom" },
                { value: "AMD radeon", label: "AMD radeon" },
                { value: "AMD ryzen", label: "AMD ryzen" },
                { value: "AMD ryzen 3", label: "AMD ryzen 3" },
                { value: "AMD ryzen 5", label: "AMD ryzen 5" },
                { value: "AMD ryzen 7", label: "AMD ryzen 7"},
                { value: "AMD ryzen theadripper", label: "AMD ryzen theadripper"},
                { value: "AMD sempron", label: "AMD sempron"},
                { value: "AMD v-series", label: "AMD v-series"},
                { value: "Apple series a", label: "Apple series a"},
                { value: "Apple series 2", label: "Apple series 2"},
                { value: "ARM cortex-a", label: "ARM cortex-a"},
                { value: "ARM cortex-m", label: "ARM cortex-m"},
                { value: "ARM mali", label: "ARM mali"},
                { value: "Hisilicon kirin", label: "Hisilicon kirin"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel chipset", label: "Intel chipset"},
                { value: "Intel core 2 duo", label: "Intel core 2 duo"},
                { value: "Intel core i3", label: "Intel core i3"},
                { value: "Intel core i5", label: "Intel core i5"},
                { value: "Intel core i7", label: "Intel core i7"},
                { value: "Intel core i9", label: "Intel core i9"},
                { value: "Intel core m", label: "Intel core m"},
                { value: "Intel core m3", label: "Intel core m3"},
                { value: "Intel core m5", label: "Intel core m5"},
                { value: "Intel xeon", label: "Intel xeon"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel pentium", label: "Intel pentium"},
                { value: "Mediatek", label: "Mediatek"},
                { value: "Mediatek helio", label: "Mediatek helio"},
                { value: "Nvidia tegra", label: "Nvidia tegra"},
                { value: "Qualcomm", label: "Qualcomm"},
                { value: "Qualcomm snapdragon", label: "Qualcomm snapdragon"},
                { value: "Rockchip", label: "Rockchip"},
                { value: "Samsung exynos", label: "Samsung exynos"},
                { value: "Apple Chip M1", label: "Apple Chip M1"},
                { value: "Otros", label: "Otros"},
                { value: "Apple A13 Bionic", label: "Apple A13 Bionic"},

              ]}
              onChange={handleProcesadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Nucleos del procesador"
              options={[
                { value: "Deca core", label: "Deca core" },
                { value: "Dual core", label: "Dual core" },
                { value: "Hexa core", label: "Hexa core" },
                { value: "No aplica", label: "No aplica" },
                { value: "Octa core", label: "Octa core" },
                { value: "Quad core", label: "Quad core" },
                { value: "Single core", label: "Single core" },
                { value: "Triple core", label: "Triple core" },
                { value: "12 Core", label: "12 Core" },
                { value: "14 Core", label: "14 Core" },
                { value: "16 Core", label: "16 Core" },
                { value: "24 Core", label: "24 Core" },
              ]}
              onChange={handleNucleosDelProcesadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
        {(atribEspecial === 837 || selectedSubcategoria === "837") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Atributos Especiales</label>
            <Select
              options={[
                { value: "Allwinner serie A", label: "Allwinner serie A" },
                { value: "AMD a10", label: "AMD a10" },
                { value: "AMD a12", label: "AMD a12" },
                { value: "AMD a4", label: "AMD a4" },
                { value: "AMD a6", label: "AMD a6" },
                { value: "AMD a9", label: "AMD a9" },
                { value: "AMD a-series", label: "AMD a-series" },
                { value: "AMD athlon", label: "AMD athlon" },
                { value: "AMD c-series", label: "AMD c-series" },
                { value: "AMD e2", label: "AMD e2" },
                { value: "AMD epyc", label: "AMD epyc" },
                { value: "AMD e-series", label: "AMD e-series" },
                { value: "AMD fx-series", label: "AMD fx-series" },
                { value: "AMD opteron", label: "AMD opteron" },
                { value: "AMD phenom", label: "AMD phenom" },
                { value: "AMD radeon", label: "AMD radeon" },
                { value: "AMD ryzen", label: "AMD ryzen" },
                { value: "AMD ryzen 3", label: "AMD ryzen 3" },
                { value: "AMD ryzen 5", label: "AMD ryzen 5" },
                { value: "AMD ryzen 7", label: "AMD ryzen 7"},
                { value: "AMD ryzen theadripper", label: "AMD ryzen theadripper"},
                { value: "AMD sempron", label: "AMD sempron"},
                { value: "AMD v-series", label: "AMD v-series"},
                { value: "Apple series a", label: "Apple series a"},
                { value: "Apple series 2", label: "Apple series 2"},
                { value: "ARM cortex-a", label: "ARM cortex-a"},
                { value: "ARM cortex-m", label: "ARM cortex-m"},
                { value: "ARM mali", label: "ARM mali"},
                { value: "Hisilicon kirin", label: "Hisilicon kirin"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel chipset", label: "Intel chipset"},
                { value: "Intel core 2 duo", label: "Intel core 2 duo"},
                { value: "Intel core i3", label: "Intel core i3"},
                { value: "Intel core i5", label: "Intel core i5"},
                { value: "Intel core i7", label: "Intel core i7"},
                { value: "Intel core i9", label: "Intel core i9"},
                { value: "Intel core m", label: "Intel core m"},
                { value: "Intel core m3", label: "Intel core m3"},
                { value: "Intel core m5", label: "Intel core m5"},
                { value: "Intel xeon", label: "Intel xeon"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel pentium", label: "Intel pentium"},
                { value: "Mediatek", label: "Mediatek"},
                { value: "Mediatek helio", label: "Mediatek helio"},
                { value: "Nvidia tegra", label: "Nvidia tegra"},
                { value: "Qualcomm", label: "Qualcomm"},
                { value: "Qualcomm snapdragon", label: "Qualcomm snapdragon"},
                { value: "Rockchip", label: "Rockchip"},
                { value: "Samsung exynos", label: "Samsung exynos"},
                { value: "Apple Chip M1", label: "Apple Chip M1"},
                { value: "Otros", label: "Otros"},
                { value: "Apple A13 Bionic", label: "Apple A13 Bionic"},

              ]}
              onChange={handleProcesadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Nucleos del procesador"
              options={[
                { value: "Deca core", label: "Deca core" },
                { value: "Dual core", label: "Dual core" },
                { value: "Hexa core", label: "Hexa core" },
                { value: "No aplica", label: "No aplica" },
                { value: "Octa core", label: "Octa core" },
                { value: "Quad core", label: "Quad core" },
                { value: "Single core", label: "Single core" },
                { value: "Triple core", label: "Triple core" },
                { value: "12 Core", label: "12 Core" },
                { value: "14 Core", label: "14 Core" },
                { value: "16 Core", label: "16 Core" },
                { value: "24 Core", label: "24 Core" },
              ]}
              onChange={handleNucleosDelProcesadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
        )}
        {(atribEspecial === 3249 || selectedSubcategoria === "3249") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Procesador</label>
            <Select
              options={[
                { value: "Allwinner serie A", label: "Allwinner serie A" },
                { value: "AMD a10", label: "AMD a10" },
                { value: "AMD a12", label: "AMD a12" },
                { value: "AMD a4", label: "AMD a4" },
                { value: "AMD a6", label: "AMD a6" },
                { value: "AMD a9", label: "AMD a9" },
                { value: "AMD a-series", label: "AMD a-series" },
                { value: "AMD athlon", label: "AMD athlon" },
                { value: "AMD c-series", label: "AMD c-series" },
                { value: "AMD e2", label: "AMD e2" },
                { value: "AMD epyc", label: "AMD epyc" },
                { value: "AMD e-series", label: "AMD e-series" },
                { value: "AMD fx-series", label: "AMD fx-series" },
                { value: "AMD opteron", label: "AMD opteron" },
                { value: "AMD phenom", label: "AMD phenom" },
                { value: "AMD radeon", label: "AMD radeon" },
                { value: "AMD ryzen", label: "AMD ryzen" },
                { value: "AMD ryzen 3", label: "AMD ryzen 3" },
                { value: "AMD ryzen 5", label: "AMD ryzen 5" },
                { value: "AMD ryzen 7", label: "AMD ryzen 7"},
                { value: "AMD ryzen theadripper", label: "AMD ryzen theadripper"},
                { value: "AMD sempron", label: "AMD sempron"},
                { value: "AMD v-series", label: "AMD v-series"},
                { value: "Apple series a", label: "Apple series a"},
                { value: "Apple series 2", label: "Apple series 2"},
                { value: "ARM cortex-a", label: "ARM cortex-a"},
                { value: "ARM cortex-m", label: "ARM cortex-m"},
                { value: "ARM mali", label: "ARM mali"},
                { value: "Hisilicon kirin", label: "Hisilicon kirin"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel chipset", label: "Intel chipset"},
                { value: "Intel core 2 duo", label: "Intel core 2 duo"},
                { value: "Intel core i3", label: "Intel core i3"},
                { value: "Intel core i5", label: "Intel core i5"},
                { value: "Intel core i7", label: "Intel core i7"},
                { value: "Intel core i9", label: "Intel core i9"},
                { value: "Intel core m", label: "Intel core m"},
                { value: "Intel core m3", label: "Intel core m3"},
                { value: "Intel core m5", label: "Intel core m5"},
                { value: "Intel xeon", label: "Intel xeon"},
                { value: "Intel atom", label: "Intel atom"},
                { value: "Intel celeron", label: "Intel celeron"},
                { value: "Intel pentium", label: "Intel pentium"},
                { value: "Mediatek", label: "Mediatek"},
                { value: "Mediatek helio", label: "Mediatek helio"},
                { value: "Nvidia tegra", label: "Nvidia tegra"},
                { value: "Qualcomm", label: "Qualcomm"},
                { value: "Qualcomm snapdragon", label: "Qualcomm snapdragon"},
                { value: "Rockchip", label: "Rockchip"},
                { value: "Samsung exynos", label: "Samsung exynos"},
                { value: "Apple Chip M1", label: "Apple Chip M1"},
                { value: "Otros", label: "Otros"},
                { value: "Apple A13 Bionic", label: "Apple A13 Bionic"},
              ]}
              onChange={handleProcesadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Cámara Posterior"
              options={[
                { value: "0.3 MP", label: "0.3 MP" },
                { value: "1.3 MP", label: "1.3 MP" },
                { value: "8 MP", label: "8 MP"},
                { value: "10 MP", label: "10 MP" },
                { value: "10.1 MP", label: "10.1 MP" },
                { value: "108 MP", label: "108 MP" },
                { value: "11 MP", label: "11 MP" },
                { value: "12 MP", label: "12 MP" },
                { value: "12.1 MP", label: "12.1 MP" },
                { value: "12.2 MP", label: "12.2 MP" },
                { value: "12.3 MP", label: "12.3 MP" },
                { value: "13 MP", label: "13 MP" },
                { value: "14 MP", label: "14 MP" },
                { value: "14.1 MP", label: "14.1 MP" },
                { value: "14.2 MP", label: "14.2 MP" },
                { value: "16 MP", label: "16 MP" },
                { value: "16.1 MP", label: "16.1 MP" },
                { value: "16.2 MP", label: "16.2 MP" },
                { value: "17 MP", label: "17 MP" },
                { value: "18 MP", label: "18 MP" },
              ]}
              onChange={handleCamaraPosteriorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
          
        )}
                {(atribEspecial === 2668 || selectedSubcategoria === "2668") && (
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-semibold text-gray-700">Incluye Cargador?</label>
            <Select
              options={[
                { value: "Sí", label: "Si" },
                { value: "No", label: "No" },
              ]}
              onChange={handleIncluyeCargadorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Generación"
              options={[
                { value: "2G", label: "2G" },
                { value: "3G", label: "3G" },
                { value: "4G", label: "4G" },
                { value: "4G LTE", label: "4G LTE" },
                { value: "5G", label: "5G" },
              ]}
              onChange={handleGeneracionChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
            <Select placeholder="Capacidad de almacenamiento" 
              options={[
                { value:"3MB", label:"3MB"},
                { value:"4MB", label:"4MB"},
                { value:"5MB", label:"5MB"},
                { value:"24MB", label:"24MB"},
                { value:"128MB", label:"128MB"},
                { value:"1 GB", label:"1GB"},
                { value:"2 GB", label:"2GB"},
                { value:"3GB", label:"3GB"},
                { value:"4 GB", label:"4GB"},
                { value:"8 GB", label:"8GB"},
                { value:"16 GB", label:"16GB"},
                { value:"24GB", label:"24GB"},
                { value:"30GB", label:"30GB"},
                { value:"32 GB", label:"32GB"},
                { value:"50GB", label:"50GB"},
                { value:"64 GB", label:"64GB"},
                { value:"80GB", label:"80GB"},
                { value:"96GB", label:"96GB"},
                { value:"120 GB", label:"120GB"},
                { value:"128 GB", label:"128GB"},
                { value:"160GB", label:"160GB"},
                { value:"240 GB", label:"240GB"},
                { value:"250 GB", label:"250GB"},
                { value:"256GB", label:"256GB"},
                { value:"320GB", label:"320GB"},
                { value:"480GB", label:"480GB"},
                { value:"500GB", label:"500GB"},
                { value:"512GB", label:"512GB"},
                { value:"750GB", label:"750GB"},
                { value:"825GB", label:"825GB"},
                { value:"960GB", label:"960GB"},
                { value:"1 TB", label:"1TB"},
                { value:"1,5TB", label:"1,5TB"},
                { value:"2TB", label:"2TB"},
                { value:"2.5TB", label:"2.5TB"},  
                { value:"3 TB", label:"3TB"},  
                { value:"4 TB", label:"4TB"},
                { value:"5 TB o más", label:"5 TB o más"},
                { value:"No aplica", label:"No aplica"},    
              ]}
              onChange={handleCapacidadDeAlmacenamientoChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          <Select placeholder="Cámara Posterior"
              options={[
                { value: "0.3 MP", label: "0.3 MP" },
                { value: "1.3 MP", label: "1.3 MP" },
                { value: "8 MP", label: "8 MP"},
                { value: "10 MP", label: "10 MP" },
                { value: "10.1 MP", label: "10.1 MP" },
                { value: "108 MP", label: "108 MP" },
                { value: "11 MP", label: "11 MP" },
                { value: "12 MP", label: "12 MP" },
                { value: "12.1 MP", label: "12.1 MP" },
                { value: "12.2 MP", label: "12.2 MP" },
                { value: "12.3 MP", label: "12.3 MP" },
                { value: "13 MP", label: "13 MP" },
                { value: "14 MP", label: "14 MP" },
                { value: "14.1 MP", label: "14.1 MP" },
                { value: "14.2 MP", label: "14.2 MP" },
                { value: "16 MP", label: "16 MP" },
                { value: "16.1 MP", label: "16.1 MP" },
                { value: "16.2 MP", label: "16.2 MP" },
                { value: "17 MP", label: "17 MP" },
                { value: "18 MP", label: "18 MP" },
              ]}
              onChange={handleCamaraPosteriorChange}
              isSearchable
              className="border border-gray-300 rounded-md"
            />
          </div>
          
        )}
{/* Agregar más categorías */}

        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Descripción Producto
          </label>
          <input
            type="text"
            value={description}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Marcas
          </label>
          <Select 
            options={brands} 
            isSearchable 
            className="border border-gray-300 rounded-md"
            defaultValue={brand}
            onChange={handleBrandChange}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700" htmlFor="producto">Condición del producto</label>
          <input 
            className="px-3 py-2 border border-gray-300 rounded-md"
            id="producto"
            type="text"
            value={conditionType}
            onChange={handleCondition}
            readOnly 
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Tipo de Envío
          </label>
          <input
            type="text"
            value={shipmentType}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleShipmentType}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Peso del Paquete
          </label>
          <input
            type="text"
            value={packageWeight}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handlePackageWeight}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Altura del Paquete
          </label>
          <input
            type="text"
            value={packageHeight}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handlePackageHeight}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Largo del Paquete
          </label>
          <input
            type="text"
            value={packageLength}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handlePackageLength}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Ancho del Paquete
          </label>
          <input
            type="text"
            value={packageWidth}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handlePackageWidth}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Precio del Producto
          </label>
          <input
            type="text"
            value={price}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handlePrice}
          />
        </div>
        <div className="flex flex-col mb-4">
          <label className="mb-2 font-semibold text-gray-700">
            Stock a Publicar
          </label>
          <input
            type="text"
            value={stock}
            className="px-3 py-2 border border-gray-300 rounded-md"
            onChange={handleStock}
          />
        </div>
        <div className="flex justify-between mt-6">
          <button
            type="submit"
            className="px-6 py-2 bg-green-500 hover:bg-green-600 text-white rounded transition duration-200 ease-in-out"
          >
            Publicar
          </button>
          <button
            type="button"
            className="px-6 py-2 bg-red-500 hover:bg-red-600 text-white rounded transition duration-200 ease-in-out"
            onClick={handleCancelarClick}
         >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};
