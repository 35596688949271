import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';

export const Comparador = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [crossFindData, setCrossFindData] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.hubapp.cl/api/productos');
        const productOptions = response.data.map(product => ({
          value: product.part_number.split('/')[0], // Aquí se eliminan los caracteres a partir de "/"
          label: product.nombre,
        }));
        setProducts(productOptions);
      } catch (error) {
        console.error("Hubo un error al obtener los productos", error);
      }
    };

    fetchProducts();
  }, []);

  const handleChange = async (selectedOption) => {
    setSelectedProduct(selectedOption);
    try {
      const response = await axios.post('https://api.hubapp.cl/api/productos/crossfindv2', { partnumber: selectedOption.value.split('/')[0] }); // Aquí también se eliminan los caracteres a partir de "/"
      console.log(response.data); // Añade esta línea
      setCrossFindData(response.data);
    } catch (error) {
      console.error("Hubo un error al buscar los datos cruzados", error);
    }
  };

  return (
    <div className="p-4">
      <Select
        value={selectedProduct}
        onChange={handleChange}
        options={products}
        className="mb-4"
      />

      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">Mayorista</th>
            <th className="px-4 py-2">Partnumber</th>
            <th className="px-4 py-2">Precio</th>
            <th className="px-4 py-2">Descripción</th>
            <th className="px-4 py-2">Stock</th>
          </tr>
        </thead>
        <tbody>
          {crossFindData.tecnoglobalProduct?.map(product => (
            <tr key={product._id}>
              <td className="border px-4 py-2">Tecnoglobal</td>
              <td className="border px-4 py-2">{product.pnFabricante}</td>
              <td className="border px-4 py-2">{product.precio}</td>
              <td className="border px-4 py-2">{product.descripcion}</td>
              <td className="border px-4 py-2">{product.stockDisp}</td>
            </tr>
          ))}
          {crossFindData.intcomexProduct?.map(product => (
            <tr key={product._id}>
              <td className="border px-4 py-2">Intcomex</td>
              <td className="border px-4 py-2">{product.Mpn}</td>
              <td className="border px-4 py-2">{product.Price}</td>
              <td className="border px-4 py-2">{product.Description}</td>
              <td className="border px-4 py-2">{product.Stock}</td>
            </tr>
          ))}
          {crossFindData.ingramProduct?.map(product => (
            <tr key={product._id}>
              <td className="border px-4 py-2">Ingram</td>
              <td className="border px-4 py-2">{product.vendorPartNumber}</td>
              <td className="border px-4 py-2">{product.price}</td>
              <td className="border px-4 py-2">{product.description}</td>
              <td className="border px-4 py-2">{product.stock}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
