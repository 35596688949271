import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const SellersShowMetrics = () => {
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    axios.get("https://api.hubapp.cl/api/seller/falabella/getmetrics")
      .then(response => {
        setMetrics(response.data.SuccessResponse.Body[0].Metrics[0].MetricsType[0]);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div className='container mx-auto px-4'>
      <div className="my-5">
        <table className='table-auto w-full'>
          <thead>
            <tr className='bg-gray-200'>
              <th className='px-4 py-2'>Tipo</th>
              <th className='px-4 py-2'>Sku</th>
              <th className='px-4 py-2'>Sku Activo</th>
              <th className='px-4 py-2'>Total Ventas</th>
              <th className='px-4 py-2'>Ordenes</th>
              <th className='px-4 py-2'>Comisiones</th>
            </tr>
          </thead>
          <tbody>
            <tr className='text-center'>
              <td className='border px-4 py-2'>{metrics.StatisticsType}</td>
              <td className='border px-4 py-2'>{metrics.SkuNumber}</td>
              <td className='border px-4 py-2'>{metrics.SkuActive}</td>
              <td className='border px-4 py-2'>{metrics.SalesTotal}</td>
              <td className='border px-4 py-2'>{metrics.Orders}</td>
              <td className='border px-4 py-2'>{metrics.Commissions}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
