import React, { useState } from 'react';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';

import { kanbanData, kanbanGrid } from '../data/dummy';


const Kanban = () => {
  const [newTask, setNewTask] = useState('');
  const [data, setData] = useState(kanbanData);

  const handleAddTask = () => {
    if (newTask.trim() !== '') {
      const updatedData = [...data];
      const newTaskItem = {
        Id: Math.max(...data.map((task) => task.Id)) + 1,
        Summary: newTask,
        Status: 'Open',
      };
      updatedData.push(newTaskItem);
      setData(updatedData);
      setNewTask('');
    }
  };

  // Agregar la propiedad 'Status' a todos los elementos en `kanbanData`
  const updatedKanbanData = data.map((task) => ({
    ...task,
    Status: task.Status || 'Open', // Establecer 'Open' si la propiedad 'Status' no está definida
  }));

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div className="flex mb-4">
        <input
          type="text"
          value={newTask}
          onChange={(e) => setNewTask(e.target.value)}
          className="p-2 border border-gray-200 rounded mr-2"
          placeholder="Nueva tarea"
        />
        <button
          type="button"
          onClick={handleAddTask}
          className="py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Agregar
        </button>
      </div>

      <div id={null}>
        <KanbanComponent
          keyField="Status"
          dataSource={updatedKanbanData}
          cardSettings={{ contentField: 'Summary', headerField: 'Id' }}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {kanbanGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          </ColumnsDirective>
        </KanbanComponent>
      </div>
    </div>
  );
};

export default Kanban;
