import React from 'react';

export const Test = () => {
    return(
        <div className="flex justify-center">
      <table className="table-auto border-collapse">
        <thead>
          <tr>
            <th className="px-4 py-2">Nombre Producto</th>
            <th className="px-4 py-2">VALOR</th>
            <th className="px-4 py-2">SKU MAYORISTA</th>
            <th className="px-4 py-2">PN</th>
            <th className="px-4 py-2">STOCK</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">Producto 1</td>
            <td className="border px-4 py-2">$10</td>
            <td className="border px-4 py-2">SKU001</td>
            <td className="border px-4 py-2">PN001</td>
            <td className="border px-4 py-2">10</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Producto 2</td>
            <td className="border px-4 py-2">$20</td>
            <td className="border px-4 py-2">SKU002</td>
            <td className="border px-4 py-2">PN002</td>
            <td className="border px-4 py-2">5</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Producto 3</td>
            <td className="border px-4 py-2">$15</td>
            <td className="border px-4 py-2">SKU003</td>
            <td className="border px-4 py-2">PN003</td>
            <td className="border px-4 py-2">8</td>
          </tr>
        </tbody>
      </table>
    </div>
    )
}