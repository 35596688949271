// Importando los componentes necesarios
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// Importando los componentes locales
import { Navbar, Sidebar, ThemeSettings } from "./components";
import { Ecommerce, Calendar, Stacked, Kanban } from "./pages";
import { SellersShowOrder } from "./pages/SellersShowOrders";
import { SellersShowMetrics } from "./pages/SellersShowMetrics";
import { SellersShow } from "./pages/SellersShow";
import { SellersAdd } from "./pages/SellersAdd";
import { MayoristasAdd } from "./pages/MayoristasAdd";
import { MayoristasShow } from "./pages/MayoristasShow";
import { TecnogProducts } from "./pages/TecnogProducts";
import { IntcomexProducts } from "./pages/IntcomexProducts";
import { AgregandoProducto } from "./pages/AgregandoProductos";
import { AgregandoProductoIngram } from "./pages/AgregandoProductoIngram";
import { AgregandoProductoIntcomex } from "./pages/AgregandoProductoIntcomex";
import { AgregarProductoManual } from "./pages/AgregarProductoManual";
import { Productos } from "./pages/Productos";
import { PublicandoProducto } from "./pages/PublicandoProducto";
import { CrearUsuario } from "./pages/CrearUsuarios";
import { UsuariosRegistrados } from "./pages/UsuariosRegistrados";
import { Test } from "./pages/Test";
import { Comparador } from "./pages/Comparador";
import { IngramProducts } from "./pages/IngramProducts";
import { VariablesGlobales } from "./pages/VariablesGlobales";
// Importando el contexto de estado
import { useStateContext } from "./contexts/ContextProvider";
// Importando el formulario de inicio de sesión
import { LoginForm } from "./components/LoginForm";
// Importando los estilos de la aplicación
import "./App.css";
// Creando el componente de la aplicación
const App = () => {
  // Usando el contexto de estado
  const { activeMenu, themeSettings, setThemeSettings } = useStateContext();
  // Estado para controlar si el usuario ha iniciado sesión
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  // Manejador para el éxito del inicio de sesión
  const handleLoginSuccess = (user) => {
    setIsLoggedIn(true);
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
      setIsLoggedIn(true);
    }
  }, []); 
  // Retornando el componente de la aplicación
  return (
    <div>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Configuración" position="Top">
              <button
                type="button"
                className="text-3xl p-3 hover:drop-shadow-xl hover:bg-light-gray text-white"
                style={{ background: "green", borderRadius: "50%" }}
                onClick={() => setThemeSettings(true)}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {!isLoggedIn ? (
            <div className="w-full flex justify-center items-center">
              <LoginForm onLoginSuccess={handleLoginSuccess} />
            </div>
          ) : (
            <>
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
              <div
                className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full ${
                  activeMenu ? "md:ml-72" : "flex-2"
                }`}
              >
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
                <Navbar user={user} />
                </div>
                <div>
                  {themeSettings && <ThemeSettings />}
                  <Routes>
                    <Route path="/" element={<Ecommerce />} />
                    <Route path="/dashboard" element={<Ecommerce />} />
                    <Route path="/ordenes" element={<SellersShowOrder />} />
                    <Route path="/metricas" element={<SellersShowMetrics />} />
                    <Route
                      path="/sellers registrados"
                      element={<SellersShow />}
                    />
                    <Route path="/registrar seller" element={<SellersAdd />} />
                    <Route path="/kanban" element={<Kanban />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/test" element={<AgregarProductoManual />} />
                    <Route
                      path="/agregando-producto"
                      element={<AgregandoProducto />}
                    />
                    <Route 
                      path="/agregando-producto-ingram"
                      element={<AgregandoProductoIngram />}
                    />
                    <Route
                      path="/agregando-producto-intcomex"
                      element={<AgregandoProductoIntcomex />}
                    />
                    <Route path="/productos" element={<Productos />} />
                    <Route
                      path="/mayoristas registrados"
                      element={<MayoristasShow />}
                    />
                    <Route
                      path="/registrar mayorista"
                      element={<MayoristasAdd />}
                    />
                    <Route
                      path="/productos tecnoglobal"
                      element={<TecnogProducts />}
                    />
                    <Route
                      path="/productos ingram"
                      element={<IngramProducts />}
                    />
                    <Route
                      path="/publicar-producto"
                      element={<PublicandoProducto />}
                    />
                    <Route path="/stacked" element={<Stacked />} />
                    <Route
                      path="/registrar usuarios"
                      element={<CrearUsuario />}
                    />
                    <Route
                      path="/usuarios registrados"
                      element={<UsuariosRegistrados />}
                    />
                    <Route
                      path="/variables globales"
                      element={<VariablesGlobales />}
                    />
                    <Route path="/productos intcomex" element={<IntcomexProducts />} />
                    <Route path="/test2" element={<Test/>}/>
                    <Route path='/comparador' element={<Comparador />} />
                  </Routes>
                </div>
              </div>
            </>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
};
// Exportando el componente de la aplicación
export default App;
