import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export const Productos = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [isAscending, setIsAscending] = useState(false);

  const handleSortByStock = () => {
    setIsAscending(!isAscending);
    setProducts((prevProducts) =>
      [...prevProducts].sort((a, b) => {
        const stockA = a.stock_publicado;
        const stockB = b.stock_publicado;
        return isAscending ? stockA - stockB : stockB - stockA;
      })
    );
  };

  const handleOpenAccordion = (productId) => {
    setSelectedProductId(productId);
  };

  const navigate = useNavigate();

  const handlePublicarProducto = (product) => {
    navigate("/publicar-producto", {
      state: {
        productToPublish: {
          id: product.id,
          eanupc_code: product.eanupc_code,
          sku_interno: product.sku_interno,
          nombre: product.nombre,
          desc_larga: product.desc_larga,
          weight: product.weight,
          volume: product.volume,
          length: product.length,
          width: product.width,
          height: product.height,
          venta_bruto: product.venta_bruto,
          stock_publicado: product.stock_publicado,
          part_number: product.part_number
        },
      },
    });
  };

  const handleEliminarProducto = (id) => {
    axios
      .delete(
        `https://api.hubapp.cl/api/productos/${id}`
      )
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== id)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(
        "https://api.hubapp.cl/api/productos"
      );
      setProducts(response.data);
    };
    fetchProducts();
  }, []);

  const filteredProducts = products.filter((product) => {
    const searchLower = searchTerm.toLowerCase();

    return (
      (product.nombre && product.nombre.toLowerCase().includes(searchLower)) ||
      (product.marca && product.marca.toLowerCase().includes(searchLower)) ||
      (product.part_number &&
        product.part_number.toLowerCase().includes(searchLower)) ||
      (product.sku_interno &&
        product.sku_interno.toLowerCase().includes(searchLower))
    );
  });

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAsociarProducto = async (product) => {
    const crossfindEndpoint = `https://api.hubapp.cl/api/productos/crossfindv2`;

    const payload = {
      partnumber: product.part_number
    };

    try {
      const response = await axios.post(crossfindEndpoint, payload);

      if (response.status === 200) {
        const mayoristasEncontrados = Object.keys(response.data);
        if (mayoristasEncontrados.length > 1) {
          alert("El producto está en más de un mayorista.");
        } else if (mayoristasEncontrados.length === 1) {
          alert("El producto se encuentra en 1 mayorista.");
        } else {
          alert("El producto no se encuentra en ningún mayorista.");
        }
      } else {
        console.log("Error al verificar el producto en los mayoristas es un 200 o no?");
      }
    } catch (error) {
      console.log("Error al verificar el producto en los mayoristas", error);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <div className="flex items-center justify-center mt-5 mb-5">
        <input
          type="text"
          placeholder="Filtro por Nombre de Producto, Marca, o Part Number"
          value={searchTerm}
          onChange={handleSearch}
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse w-full">
          <thead>
            <tr
              className="rounded-lg text-sm font-medium text-gray-700 text-left"
              style={{ fontSize: "0.9674rem" }}
            >
              <th className="px-8 py-2 bg-gray-200">Nombre</th>
              <th className="px-4 py-2 bg-gray-200">Marca</th>
              <th className="px-4 py-2 bg-gray-200">SKU INTERNO</th>
              <th className="px-4 py-2 bg-gray-200">Part Number</th>
              <th className="px-4 py-2 bg-gray-200">Compra Neto</th>
              <th className="px-4 py-2 bg-gray-200">Venta Neto</th>
              <th className="px-4 py-2 bg-gray-200">Venta Bruto</th>
              <th className="px-4 py-2 bg-gray-200">IVA Venta</th>
              <th className="px-4 py-2 bg-gray-200 cursor-pointer hover:bg-green-300" onClick={handleSortByStock}>
                Stock <FontAwesomeIcon icon={faSortDown} className="ml-1 hover:bg-green-200" />
              </th>
              <th className="px-4 py-2 bg-gray-200">Estado</th>
              <th className="px-4 py-2 bg-gray-200">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts
              .slice(
                currentPage * itemsPerPage,
                (currentPage + 1) * itemsPerPage
              )
              .map((product) => (
                <React.Fragment key={product.id}>
                  <tr>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.nombre}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.marca}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.sku_interno}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.part_number}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.compra_neto}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.venta_neto}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.venta_bruto}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.IVA_venta}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.stock_publicado}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      {product.createdAt}
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      <p>Publicado</p>
                    </td>
                    <td className="px-4 py-2 border-b border-gray-200 bg-white text-sm">
                      <button
                        className="px-3 py-1 m-1 bg-green-400 text-white rounded-md hover:bg-green-500"
                        onClick={() => handlePublicarProducto(product)}
                      >
                        Publicar
                      </button>
                      <button
                        className="px-4 py-1 m-1 bg-blue-400 text-white rounded-md hover:bg-blue-500"
                        onClick={() => handleOpenAccordion(product.id)}
                      >
                        Desplegar
                      </button>
                      <button
                        className="px-7 py-1 m-1 bg-red-400 text-white rounded-md hover:bg-red-500"
                        onClick={() => handleEliminarProducto(product.id)}
                      >
                        Eliminar
                      </button>

                    </td>
                  </tr>
                  {selectedProductId === product.id && (
                    <tr>
                      <td colSpan="11">
                        <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md">
                          <h3 className="text-xl font-medium text-black">Datos del producto</h3>
                          <ul className="text-gray-500">
                            {product.mayoristas.map((mayorista) => (
                              <li key={mayorista.id}>Mayorista: {mayorista.nombre}, SKU: {mayorista.products_mayoristas.SKU_Mayorista}</li>
                            ))}
                            {product.sellers.map((sellers) => (
                              <li key={sellers.id}>Publicado en: {sellers.nombre}</li>
                            ))}

                            <li key={product.id}>Eanupc: {product.eanupc_code}</li>

                          </ul>
                          <button className="bg-green-400 hover:bg-green-500 text-white font-light py-1 px-2 rounded" onClick={() => handleAsociarProducto(product)}>
                            Asociar
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
        onPageChange={handlePageChange}
        containerClassName={"pagination flex justify-center space-x-2"}
        pageClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-500 hover:text-white duration-500"
        }
        previousClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        nextClassName={
          "page px-3 py-2 border rounded text-sm cursor-pointer hover:bg-green-700 hover:text-white duration-500"
        }
        breakClassName={"break px-3 py-2 border rounded text-sm cursor-pointer"}
        activeClassName={"active bg-green-500 text-white"}
      />
    </div>
  );
};
