import React, { useState, useEffect } from "react";
import axios from "axios";

export const MayoristasShow = () => {
  const [mayoristas, setMayoristas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingMayorista, setEditingMayorista] = useState(null);
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [web, setWeb] = useState("");
  const [email_general, setEmailGeneral] = useState("");

  useEffect(() => {
    axios
      .get("https://api.hubapp.cl/api/mayoristas")
      .then((response) => {
        setMayoristas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleEliminarMayorista = (id) => {
    axios
      .delete(`https://api.hubapp.cl/api/mayoristas/${id}`)
      .then(() => {
        setMayoristas(mayoristas.filter((mayorista) => mayorista.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditarMayorista = (mayorista) => {
    setEditingMayorista(mayorista);
    setShowModal(true);
    setNombre(mayorista.nombre);
    setTelefono(mayorista.telefono);
    setDireccion(mayorista.direccion);
    setWeb(mayorista.web);
    setEmailGeneral(mayorista.email_general);
  };

  const handleCerrarModal = () => {
    setEditingMayorista(null);
    setShowModal(false);
    setNombre("");
    setTelefono("");
    setDireccion("");
    setWeb("");
    setEmailGeneral("");
  };

  const handleNombreChange = (event) => {
    setNombre(event.target.value);
    setEditingMayorista({
      ...editingMayorista,
      nombre: event.target.value,
    });
  };

  const handleTelefonoChange = (event) => {
    setTelefono(event.target.value);
    setEditingMayorista({
      ...editingMayorista,
      telefono: event.target.value,
    });
  };

  const handleDireccionChange = (event) => {
    setDireccion(event.target.value);
    setEditingMayorista({
      ...editingMayorista,
      direccion: event.target.value,
    });
  };

  const handleWebChange = (event) => {
    setWeb(event.target.value);
    setEditingMayorista({
      ...editingMayorista,
      web: event.target.value,
    });
  };

  const handleEmailGeneralChange = (event) => {
    setEmailGeneral(event.target.value);
    setEditingMayorista({
      ...editingMayorista,
      email_general: event.target.value,
    });
  };

  const handleGuardarCambios = () => {
    axios
      .put(
        `https://api.hubapp.cl/api/mayoristas/${editingMayorista.id}`,
        editingMayorista
      )
      .then((response) => {
        const index = mayoristas.findIndex(
          (mayorista) => mayorista.id === editingMayorista.id
        );
        mayoristas[index] = response.data;
        setMayoristas([
          ...mayoristas.slice(0, index),
          response.data,
          ...mayoristas.slice(index + 1),
        ]);

        handleCerrarModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container mx-auto px-4">
      <div className="my-5">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="px-4 py-2">
                      Nombre
                    </th>
                    <th className="px-4 py-2">
                      Teléfono
                    </th>
                    <th className="px-4 py-2">
                      Dirección
                    </th>
                    <th className="px-4 py-2">
                      Sitio Web
                    </th>
                    <th className="px-4 py-2">
                      Email General
                    </th>
                    <th className="px-4 py-2">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(mayoristas) &&
                    mayoristas.map((mayorista) => (
                      <tr key={mayorista.id} className="bg-white">
                        <td className="border px-4 py-2">
                          {mayorista.nombre}
                        </td>
                        <td className="border px-4 py-2">
                          {mayorista.telefono}
                        </td>
                        <td className="border px-4 py-2">
                          {mayorista.direccion}
                        </td>
                        <td className="border px-4 py-2">
                          {mayorista.web}
                        </td>
                        <td className="border px-4 py-2">
                          {mayorista.email_general}
                        </td>
                        <td className="border px-4 py-2">
                          <button
                            className="mx-1 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded duration-500"
                            onClick={() => handleEditarMayorista(mayorista)}
                          >
                            Editar
                          </button>
                          <button
                            className="mx-1 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded duration-500"
                            onClick={() =>
                              handleEliminarMayorista(mayorista.id)
                            }
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              ​
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Editar Mayorista
                    </h3>
                    <div className="mt-2">
                      <div className="mt-2">
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="nombre"
                          >
                            Nombre
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="nombre"
                            type="text"
                            placeholder={nombre}
                            onChange={handleNombreChange}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="telefono"
                          >
                            Teléfono
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="telefono"
                            type="text"
                            placeholder={telefono}
                            onChange={handleTelefonoChange}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="direccion"
                          >
                            Dirección
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="direccion"
                            type="text"
                            placeholder={direccion}
                            onChange={handleDireccionChange}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="web"
                          >
                            Sitio Web
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="web"
                            type="text"
                            placeholder={web}
                            onChange={handleWebChange}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="email_general"
                          >
                            Email General
                          </label>
                          <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email_general"
                            type="email"
                            placeholder={email_general}
                            onChange={handleEmailGeneralChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleGuardarCambios}
                >
                  Guardar
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCerrarModal}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
