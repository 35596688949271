import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const VariablesGlobales = () => {
  const [data, setData] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedVariable, setEditedVariable] = useState({});
  const [loading, setLoading] = useState(false);

  const openEditModal = (variable) => {
    setEditedVariable(variable);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditedVariable({});
    setEditModalOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await axios.put(`https://api.hubapp.cl/api/variable/${editedVariable.id}`, editedVariable);
      // Manejar la respuesta de la solicitud PUT según sea necesario
      console.log('Variable editada con éxito', response.data);
      closeEditModal();
    } catch (error) {
      console.error('Error al editar la variable', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Carga inicial de datos desde el endpoint
    axios
      .get('https://api.hubapp.cl/api/variable')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error al obtener datos:', error);
      });
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Datos de la API</h1>
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="border px-4 py-2">ID</th>
            <th className="border px-4 py-2">Margen Default</th>
            <th className="border px-4 py-2">Impuesto</th>
            <th className="border px-4 py-2">Stock Crit Default</th>
            <th className="border px-4 py-2">País</th>
            <th className="border px-4 py-2">Código de País</th>
            <th className="border px-4 py-2">Moneda</th>
            <th className="border px-4 py-2">Código de Moneda</th>
            <th className="border px-4 py-2">Símbolo de Moneda</th>
            <th className="border px-4 py-2">Fecha de Creación</th>
            <th className="border px-4 py-2">Fecha de Actualización</th>
            <th className="border px-4 py-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td className="border px-4 py-2">{item.id}</td>
              <td className="border px-4 py-2">{item.margen_default}</td>
              <td className="border px-4 py-2">{item.impuesto}</td>
              <td className="border px-4 py-2">{item.stock_crit_default}</td>
              <td className="border px-4 py-2">{item.pais}</td>
              <td className="border px-4 py-2">{item.pais_code}</td>
              <td className="border px-4 py-2">{item.moneda}</td>
              <td className="border px-4 py-2">{item.moneda_code}</td>
              <td className="border px-4 py-2">{item.moneda_simbolo}</td>
              <td className="border px-4 py-2">{item.createdAt}</td>
              <td className="border px-4 py-2">{item.updatedAt}</td>
              <td className="border px-4 py-2">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none"
                  onClick={() => openEditModal(item)}
                >
                  Editar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal de edición */}
      {editModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto my-6">
            <div className="modal">
              {/* Contenido del modal */}
              <form onSubmit={handleEditSubmit}>
                {/* Campos de edición */}
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Margen 
                  </label>
                  <input
                    type="number"
                    className="border rounded w-full py-2 px-3"
                    value={editedVariable.margen_default}
                    onChange={(e) =>
                      setEditedVariable({
                        ...editedVariable,
                        margen_default: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Stock Crítico
                  </label>
                  <input
                    type="number"
                    className="border rounded w-full py-2 px-3"
                    value={editedVariable.stock_crit_default}
                    onChange={(e) =>
                      setEditedVariable({
                        ...editedVariable,
                        stock_crit_default: parseInt(e.target.value),
                      })
                    }
                  />
                </div>
                {/* Repite el patrón para los demás campos */}
                {/* ... */}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    onClick={closeEditModal}
                  >
                    Cerrar
                  </button>
                  <button
                    type="submit"
                    className={`bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ${
                      loading ? 'cursor-not-allowed' : ''
                    }`}
                    disabled={loading}
                  >
                    Guardar cambios
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
